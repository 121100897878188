<template>
  <div>
    <section class="hero">
      <div class="hero-body">
        <p class="title">
          {{ i18n.newMember[iso] }} {{ (club && club.Nombre) || "" }}
        </p>
        <p class="subtitle">
          {{ i18n.validate__msg2[iso] }}
        </p>
      </div>
    </section>
    <hr />
    <div v-for="item in items.filter((it) => it.showModule())">
      <div v-if="!item.isArray">
        <div class="columns is-multiline">
          <div class="column is-12">
            <div class="content" v-if="!item.titleHide">
              <h3>{{ i18n[item.titleIso][iso] }}</h3>
              <small v-if="item.subtitleIso">{{
                i18n[item.subtitleIso][iso]
              }}</small>
            </div>
          </div>
        </div>

        <div class="columns is-multiline">
          <div
            :class="`column is-${m.size}`"
            v-for="(m, idx) in item.list.filter(
              (it) =>
                !it.clubs ||
                (it.clubs && club && club.Id && it.clubs.includes(club.Id))
            )"
            :key="idx"
          >
            <b-field
              label-position="on-border"
              :label="`${i18n[m.fieldIso][iso]} ${m.required ? '*' : ''}`"
              v-if="m.isInfo"
            >
              <b-input
                v-model="data[item.ref][m.key]"
                type="text"
                expanded
                :disabled="true"
              >
              </b-input>
            </b-field>

            <b-field
              :label="`${i18n[m.fieldIso][iso]} ${m.required ? '*' : ''}`"
              v-if="
                (m.type === 'text' ||
                  m.type === 'email' ||
                  m.type === 'number' ||
                  m.type === 'text-custom') &&
                m.isInfo === false
              "
              :type="
                m.required &&
                (!data[item.ref][m.key] || data[item.ref][m.key] === '')
                  ? 'is-danger'
                  : ''
              "
              :message="
                m.key === 'Saldo' && SaldoNumber !== null && SaldoNumber < 0
                  ? i18n.validate__msg10_saldo[iso]
                  : ''
              "
            >
              <b-input
                v-if="m.type === 'number'"
                v-model="data[item.ref][m.key]"
                type="number"
                icon-right="times"
                icon-pack="fas"
                icon-right-clickable
                @icon-right-click="data[item.ref][m.key] = null"
                expanded
                :icon="m.icon"
                :min="m.min"
                :maxlength="m.max || 30"
                :has-counter="m.max && true"
                :disabled="m.disabled"
              >
              </b-input>

              <div
                v-if="m.type === 'text-custom'"
                class="control has-icons-left has-icons-right is-expanded is-clearfix"
              >
                <input
                  v-model="data[m.refCustom || item.ref][m.key]"
                  type="text"
                  :maxlength="m.max || 30"
                  class="input"
                  @keyup="m.fKeyUp"
                  :disabled="
                    m.disabled ||
                    (m.key === 'TasaInteres' &&
                    SaldoNumber !== null &&
                    SaldoNumber <= 0
                      ? true
                      : false)
                  "
                  @input="calcularPagos(data[item.ref][m.key], m.key)"
                />
                <span class="icon is-left" v-if="m.icon"
                  ><i :class="`fas fa-${m.icon}`"></i
                ></span>
                <span class="icon is-right is-clickable" @click="m.fClear"
                  ><i class="fas fa-times"></i
                ></span>
              </div>

              <b-input
                v-if="m.type === 'email'"
                v-model="data[item.ref][m.key]"
                type="email"
                icon-right="times"
                icon-pack="fas"
                icon-right-clickable
                @icon-right-click="data[item.ref][m.key] = null"
                expanded
                :validation-message="i18n[m.rgxIso][iso]"
                :disabled="!isEdit"
                :maxlength="m.max || 30"
                :has-counter="m.max && true"
                @input="toLowerCase(item.ref, m.key)"
              >
              </b-input>
              <b-input
                v-if="m.rgx && m.type === 'text'"
                v-model="data[item.ref][m.key]"
                type="text"
                icon-right="times"
                icon-pack="fas"
                icon-right-clickable
                @icon-right-click="data[item.ref][m.key] = null"
                expanded
                :pattern="m.rgx"
                :validation-message="i18n[m.rgxIso][iso]"
                :disabled="!isEdit"
                :maxlength="m.max || 30"
                :has-counter="m.max && true"
              >
              </b-input>
              <b-input
                v-if="!m.rgx && m.type === 'text'"
                v-model="data[item.ref][m.key]"
                type="text"
                icon-right="times"
                icon-pack="fas"
                icon-right-clickable
                @icon-right-click="data[item.ref][m.key] = null"
                expanded
                :disabled="!isEdit"
                :maxlength="m.max || 30"
                :has-counter="m.max && true"
              >
              </b-input>
            </b-field>

            <b-field
              :label="`${i18n[m.fieldIso][iso]} ${m.required ? '*' : ''}`"
              v-if="m.type === 'date' && m.isInfo === false"
              :type="m.required && !data[item.ref][m.key] ? 'is-danger' : ''"
            >
              <b-datepicker
                icon="calendar"
                icon-pack="fas"
                v-model="data[item.ref][m.key]"
                :locale="iso"
                :disabled="!isEdit"
                :date-formatter="(date) => moment(date).format('LL')"
                :years-range="[-100, 100]"
                :max-date="m.maxDate"
                :min-date="minDate"
              >
              </b-datepicker>
            </b-field>

            <b-field
              :label="`${i18n[m.fieldIso][iso]} ${m.required ? '*' : ''}`"
              v-if="m.type === 'select' && m.isInfo === false"
              :type="m.required && !data[item.ref][m.key] ? 'is-danger' : ''"
            >
              <b-select
                v-model="data[item.ref][m.key]"
                expanded
                :disabled="
                  (m.disabledExec ? m.disabled() : m.disabled) ||
                  (m.key === 'Mensualidades' &&
                  SaldoNumber !== null &&
                  SaldoNumber <= 0
                    ? true
                    : false)
                "
              >
                <option
                  v-if="!m.notNull && m.key !== 'Mensualidades'"
                  :value="null"
                >
                  {{ i18n.selectAnOption[iso] }}
                </option>
                <option
                  v-if="!m.notNull && m.key === 'Mensualidades'"
                  :value="0"
                >
                  0
                </option>
                <option
                  v-if="!m.optionsIsCatalogue"
                  v-for="cc in m.optionsExec ? m.options() : m.options"
                  :value="cc[m.valueOption]"
                >
                  {{ m.showOption(cc) }}
                </option>
                <option
                  v-if="m.optionsIsCatalogue"
                  v-for="cc in m.withFilters
                    ? m.optionsFilters(catalogues[m.options])
                    : catalogues[m.options]"
                  :value="cc[m.valueOption]"
                >
                  {{ m.showOption(cc) }}
                </option>
              </b-select>
            </b-field>

            <!-- Esto solo se hizo para contratos -->
            <b-field
              :label="`${i18n[m.fieldIso][iso]}: ${
                data[item.ref][m.key] > 80
                  ? i18n.lifetime[iso]
                  : data[item.ref][m.key]
              } ${m.required ? '*' : ''}`"
              v-if="m.type === 'slider' && m.isInfo === false"
            >
              <b-slider
                rounded
                v-model="data[item.ref][m.key]"
                :min="1"
                :max="81"
                :tooltip="data[item.ref][m.key] <= 80"
                :type="data[item.ref][m.key] > 80 ? 'is-success' : 'is-info'"
                :tooltip-type="
                  data[item.ref][m.key] > 80 ? 'is-success' : 'is-info'
                "
              >
                <b-slider-tick :value="1">1</b-slider-tick>
                <b-slider-tick :value="10">10</b-slider-tick>
                <b-slider-tick :value="20">20</b-slider-tick>
                <b-slider-tick :value="30">30</b-slider-tick>
                <b-slider-tick :value="40">40</b-slider-tick>
                <b-slider-tick :value="50">50</b-slider-tick>
                <b-slider-tick :value="60">60</b-slider-tick>
                <b-slider-tick :value="70">70</b-slider-tick>
                <b-slider-tick :value="80">80</b-slider-tick>
              </b-slider>
            </b-field>
          </div>

          <div class="column is-12">
            <hr />
          </div>
        </div>
      </div>
      <div v-if="item.isArray">
        <div class="columns is-multiline">
          <div :class="`column is-${item.sizeTitle || 3}`">
            <div class="content">
              <h3>{{ i18n[item.titleIso][iso] }}</h3>
            </div>
          </div>
          <div class="column is-2" v-if="item.addMore">
            <b-button
              icon-left="plus"
              icon-pack="fas"
              type="is-info is-light"
              expanded
              size="is-small"
              @click="item.addMore"
              >{{ i18n.add[iso] }}</b-button
            >
          </div>
        </div>
        <div class="columns">
          <div class="column is-12">
            <small v-if="item.subtitleIso">{{
              i18n[item.subtitleIso][iso]
            }}</small>
          </div>
        </div>

        <div
          class="columns is-multiline"
          v-for="(r, ridx) in data[item.ref]"
          :key="ridx"
        >
          <div
            :class="`column is-${m.size}`"
            v-for="(m, idx) in item.list"
            :key="idx"
          >
            <b-field
              :label="`${i18n[m.fieldIso][iso]} ${m.required ? '*' : ''}`"
              v-if="
                (m.type === 'text' ||
                  m.type === 'number' ||
                  m.type === 'text-custom') &&
                m.isInfo === false
              "
            >
              <b-input
                v-if="m.rgx && m.type === 'text'"
                v-model="r[m.key]"
                type="text"
                icon-right="times"
                icon-pack="fas"
                icon-right-clickable
                @icon-right-click="r[m.key] = null"
                expanded
                :disabled="!isEdit"
                :pattern="m.rgx"
                :validation-message="i18n[m.rgxIso][iso]"
                :maxlength="m.max || 30"
                :has-counter="m.max && true"
              >
              </b-input>

              <div
                v-if="m.type === 'text-custom'"
                class="control has-icons-left has-icons-right is-expanded is-clearfix"
              >
                <input
                  v-model="r[m.key]"
                  type="text"
                  :maxlength="m.max || 30"
                  class="input"
                  @keyup="m.fKeyUp(r[m.key], ridx)"
                  @input="calcularPagos(r[m.key], m.key)"
                />
                <span class="icon is-left" v-if="m.icon"
                  ><i :class="`fas fa-${m.icon}`"></i
                ></span>
                <span class="icon is-right is-clickable" @click="m.fClear(ridx)"
                  ><i class="fas fa-times"></i
                ></span>
              </div>

              <b-input
                v-if="!m.rgx && m.type === 'text'"
                v-model="r[m.key]"
                type="text"
                icon-right="times"
                icon-pack="fas"
                icon-right-clickable
                @icon-right-click="r[m.key] = null"
                expanded
                :disabled="!isEdit"
              >
              </b-input>
              <b-input
                v-if="m.type === 'number'"
                v-model="r[m.key]"
                type="number"
                icon-right="times"
                icon-pack="fas"
                icon-right-clickable
                @icon-right-click="r[m.key] = null"
                expanded
                :icon="m.icon"
                :disabled="!isEdit"
                :min="m.min || 0"
                :maxlength="m.max || 30"
                :has-counter="m.max && true"
              >
              </b-input>
            </b-field>

            <b-field
              :label="`${i18n[m.fieldIso][iso]} ${m.required ? '*' : ''}`"
              v-if="m.type === 'date' && m.isInfo === false"
              :type="m.required && !r[m.key] ? 'is-danger' : ''"
            >
              <b-datepicker
                icon="calendar"
                icon-pack="fas"
                v-model="r[m.key]"
                :locale="iso"
                :disabled="!isEdit"
                :date-formatter="(date) => moment(date).format('LL')"
                :years-range="[-100, 100]"
                :max-date="maxDateOther"
                :min-date="minDate"
              >
              </b-datepicker>
            </b-field>

            <b-field
              :label="`${i18n[m.fieldIso][iso]} ${m.required ? '*' : ''}`"
              v-if="m.type === 'select' && m.isInfo === false"
            >
              <b-select v-model="r[m.key]" expanded :disabled="!isEdit">
                <option :value="null">{{ i18n.selectAnOption[iso] }}</option>
                <option
                  v-if="!m.optionsIsCatalogue"
                  v-for="cc in m.options"
                  :value="cc[m.valueOption]"
                >
                  {{ m.showOption(cc) }}
                </option>
                <option
                  v-if="m.optionsIsCatalogue"
                  v-for="cc in m.withFilters
                    ? m.optionsFilters(catalogues[m.options])
                    : catalogues[m.options]"
                  :value="cc[m.valueOption]"
                >
                  {{ m.showOption(cc) }}
                </option>
              </b-select>
            </b-field>
            <b-field
              :label="`${i18n[m.fieldIso][iso]} ${m.required ? '*' : ''}`"
              v-if="m.type === 'button'"
            >
              <b-button
                icon-left="trash"
                icon-pack="fas"
                type="is-danger"
                expanded
                @click="m.action(ridx)"
              ></b-button>
            </b-field>
          </div>
        </div>

        <div class="columns is-multiline">
          <div class="column is-12">
            <hr />
          </div>
        </div>
      </div>
    </div>
    <div class="column is-12">
      <b-button
        icon-left="save"
        icon-pack="fas"
        type="is-success is-light"
        expanded
        @click="beforeCreateAll"
        >{{ i18n.save[iso] }}</b-button
      >
    </div>
    <b-loading
      :is-full-page="true"
      v-model="loading"
      :can-cancel="false"
    ></b-loading>
  </div>
</template>

<script>
import axios from "axios";
const API_URL = process.env.VUE_APP_API_URL;
import moment from "moment";
import i18n from "../../utils/i18n";
// import CommentMember from "../CommentMember";

const listCatalogues = [
  "CatStatusContrato",
  "CatTipoVenta",
  "CatIdiomas",
  "CatParentescos",
  "CatEstadosPais",
  "CatEstadoCivil",
  "CatTipoSocio",
  "CatPaises",
  "CatPeriodicidad",
  "CatMoneda",
];

const TEMPLATE_CONTRACT = {
  Numero: null,
  FechaCompra: null,
  EstadoContrato: null,
  Years: 1,
  Idioma: null,
  TipoMembresia: null,
  Moneda: "USD",
};

const TEMPLATE_MEMBER = {
  Nombre: null,
  ApellidoPaterno: null,
  Parentesco: null,
  // ApellidoMaterno: null,
  Email: null,
  FechaNacimiento: null,
  LadaTelefono: null,
  Telefono: null,
  LadaCelular: null,
  Celular: null,
  Pais: null,
  Estado: null,
  Ciudad: null,
  Direccion: null,
  CodigoPostal: null,
  Nacionalidad: null,
  EstadoCivil: null,
  TipoSocio: null,
  FechaRegistro: null,
  Idioma: null,
};

const TEMPLATE_NUMBER_OF_WEEKS = {
  VipWeeks: null,
  SmartWeeks: null,
  LastMinuteWeeks: null,
};

const TEMPLATE_TRAVEL_VAUCHER = {
  Monto: null,
  Cantidad: 1,
};
const TEMPLATE_TRAVEL_VAUCHER_VR = {
  Monto: 399,
  Cantidad: null,
};

const OPTIONS_WEEKS = [1, 2, 3, 4, 5, 99];
const OPTIONS_WEEKS_EXT = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 99];

const OPTIONS_INCENTIVES = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];

const DEFAULT_PARAMS = {
  // ClubId: "96213bc2-b460-414e-980a-d0bd99d0869c",
  Periodicidad: "96560a5d-73c3-4117-a49a-2998941af654",
  // Tarifa: 199,
  Moneda: "USD",
};

const SEAL = "cap_v2";

const DEFAULT_PASSWORD = "123456";

/**
 * Regex
 */
const rgxOnlyTextAndSpace = [
  `^[a-zA-Z \u00C0-\u024F\u1E00-\u1EFF]*$`,
  "rgxOnlyTextAndSpace",
];
const rgxOnlyTextAndGuion = ["^[a-zA-Z0-9-]+$", "rgxOnlyTextAndGuion"];
const rgxEmail = [
  "^[a-z0-9\._+\-]+@([a-z0-9\-]+\.){1,}([a-z]{2,16})*$",
  "rgxEmail",
];
const rgxCityAndAddress = [
  `^[a-zA-Z0-9 ,\u00C0-\u024F\u1E00-\u1EFF]*$`,
  "rgxCityAndAddress",
];
const rgxZip = [`^[0-9 ]*$`, "rgxZip"];

export default {
  data() {
    return {
      moment,
      listCatalogues,
      i18n,
      iso: "es",
      isEdit: true,
      loading: false,
      data: {
        contract: {
          ...TEMPLATE_CONTRACT,
        },
        owner: { ...TEMPLATE_MEMBER },
        coOwner: { ...TEMPLATE_MEMBER },
        beneficiaries: [],
        membershipBenefits: { ...TEMPLATE_NUMBER_OF_WEEKS },
        // incentives: {
        //   Monto: null,
        //   Cantidad: 1,
        // },
        incentives: [
          // {
          //   Monto: null,
          //   Cantidad: 1,
          // }
        ],
        detailsContract: {
          Mensualidades: 0,
        },
        conditionalDownPayment: [
          // {
          //   FechaPago: null,
          //   CargoPago: null,
          //   TipoPago: "CDP",
          // },
        ],
        additionalPayments: [
          // {
          //   FechaPago: null,
          //   CargoPago: null,
          //   TipoPago: "ADP",
          // },
        ],
      },
      catalogues: {},
      DEFAULT_PARAMS,
      SEAL,
      DEFAULT_PASSWORD,
      minDate: new Date(
        moment().set({
          year: 1930,
          month: 0,
          date: 1,
          hour: 0,
          second: 0,
          millisecond: 0,
        })
      ),
      maxDateToday: new Date(),
      maxDateBirthdate: new Date(moment().subtract(18, "years")),
      maxDateOther: new Date(moment().add(10, "years")),
      club: null,
      club__Mensualidades: [],
      SaldoNumber: null,

      /**
       * Items
       */
      items: [
        {
          titleIso: "contract",
          ref: "contract",
          showModule: () => {
            return true;
          },
          list: [
            {
              key: "Numero",
              fieldIso: "contractNumber",
              type: "text",
              size: 3,
              isInfo: false,
              required: true,
              // rgx: rgxOnlyTextAndGuion[0],
              rgxIso: rgxOnlyTextAndGuion[1],
              valRgx: rgxOnlyTextAndGuion[0],
              max: 18,
            },
            {
              key: "FechaCompra",
              fieldIso: "purchaseDate",
              type: "date",
              size: 3,
              isInfo: false,
              required: true,
              maxDate: new Date(),
            },
            {
              key: "Years",
              fieldIso: "years",
              type: "slider",
              size: 3,
              isInfo: false,
              required: true,
            },
            {
              key: "EstadoContrato",
              fieldIso: "contractStatus",
              type: "select",
              size: 3,
              isInfo: false,
              options: "CatStatusContrato",
              optionsIsCatalogue: true,
              valueOption: "Id",
              showOption: (tmp) => {
                return this.iso === "es" ? tmp.Nombre : tmp.NombreEn;
              },
              withFilters: false,
              required: true,
            },
            {
              key: "TipoMembresia",
              fieldIso: "membershipType",
              type: "select",
              size: 3,
              isInfo: false,
              options: "CatTipoVenta",
              optionsIsCatalogue: true,
              valueOption: "Id",
              showOption: (tmp) => {
                return tmp.Nombre;
              },
              withFilters: false,
              required: true,
            },
            {
              key: "Idioma",
              fieldIso: "language",
              type: "select",
              size: 3,
              isInfo: false,
              options: "CatIdiomas",
              optionsIsCatalogue: true,
              valueOption: "Id",
              showOption: (tmp) => {
                return this.iso === "es" ? tmp.Nombre : tmp.NombreEn;
              },
              required: true,
            },
            {
              key: "PrecioContrato",
              fieldIso: "priceContract",
              type: "text-custom",
              size: 3,
              isInfo: false,
              required: false,
              icon: "dollar-sign",
              fClear: () => {
                this.data.detailsContract.PrecioContrato = null;
                this.$forceUpdate();
              },
              fKeyUp: (e) => {
                const n = this.formatNumberDecimals(e.target.value);
                this.data.detailsContract.PrecioContrato = n;
                this.$forceUpdate();
              },
              refCustom: "detailsContract",
            },
            {
              key: "CuotaProcesamiento",
              fieldIso: "processingFee",
              type: "text-custom",
              size: 3,
              isInfo: false,
              required: false,
              icon: "dollar-sign",
              fClear: () => {
                this.data.detailsContract.CuotaProcesamiento = null;
                this.$forceUpdate();
              },
              fKeyUp: (e) => {
                const n = this.formatNumberDecimals(e.target.value);
                this.data.detailsContract.CuotaProcesamiento = n;
                this.$forceUpdate();
              },
              refCustom: "detailsContract",
              clubs: ["D061BF76-8D4E-42C3-97A5-9B6F067F49C8"],
            },
            {
              key: "SixImpuesto",
              fieldIso: "sixTax",
              type: "text-custom",
              size: 3,
              isInfo: false,
              required: false,
              icon: "percentage",
              fClear: () => {
                this.data.detailsContract.SixImpuesto = null;
                this.$forceUpdate();
              },
              fKeyUp: (e) => {
                const n = this.formatNumberDecimals(e.target.value);
                this.data.detailsContract.SixImpuesto = n;
                this.$forceUpdate();
              },
              refCustom: "detailsContract",
              clubs: ["D061BF76-8D4E-42C3-97A5-9B6F067F49C8"],
            },
            {
              key: "TotalProcesamiento",
              fieldIso: "total",
              type: "text-custom",
              size: 3,
              isInfo: false,
              required: false,
              icon: "dollar-sign",
              fClear: () => {
                this.data.detailsContract.TotalProcesamiento = null;
                this.$forceUpdate();
              },
              fKeyUp: (e) => {
                const n = this.formatNumberDecimals(e.target.value);
                this.data.detailsContract.TotalProcesamiento = n;
                this.$forceUpdate();
              },
              refCustom: "detailsContract",
              clubs: ["D061BF76-8D4E-42C3-97A5-9B6F067F49C8"],
            },
            {
              key: "Moneda",
              fieldIso: "currency",
              type: "select",
              size: 3,
              isInfo: false,
              options: "CatMoneda",
              optionsIsCatalogue: true,
              valueOption: "Id",
              notNull: true,
              showOption: (tmp) => {
                return this.iso === "es"
                  ? `${tmp.Nombre} (${tmp.Id})`
                  : `${tmp.NombreEn} (${tmp.Id})`;
              },
              required: false,
              disabled: () => {
                return this.club && this.club?.ModuloMoneda ? false : true;
              },
              disabledExec: true,
            },
            {
              key: "Vendedor",
              fieldIso: "seller",
              type: "text",
              size: 3,
              isInfo: false,
              required: false,
              max: 20,
            },
          ],
        },
        {
          titleIso: "owner",
          ref: "owner",
          showModule: () => {
            return true;
          },
          list: [
            {
              key: "Nombre",
              fieldIso: "name",
              type: "text",
              size: 3,
              isInfo: false,
              required: true,
              min: 1,
              max: 20,
              rgx: rgxOnlyTextAndSpace[0],
              rgxIso: rgxOnlyTextAndSpace[1],
              valRgx: rgxOnlyTextAndSpace[0],
            },
            {
              key: "ApellidoPaterno",
              fieldIso: "lastname",
              type: "text",
              size: 3,
              isInfo: false,
              required: true,
              min: 1,
              max: 20,
              rgx: rgxOnlyTextAndSpace[0],
              rgxIso: rgxOnlyTextAndSpace[1],
              valRgx: rgxOnlyTextAndSpace[0],
            },
            {
              key: "Email",
              fieldIso: "email",
              type: "email",
              size: 3,
              isInfo: false,
              required: true,
              rgx: null,
              rgxIso: rgxEmail[1],
              valRgx: rgxEmail[0],
              max: 50,
            },
            {
              key: "FechaNacimiento",
              fieldIso: "birthdate",
              type: "date",
              size: 3,
              isInfo: false,
              required: false,
              maxDate: new Date(moment().subtract(18, "years")),
            },
            {
              key: "LadaTelefono",
              fieldIso: "codCellphone",
              type: "select",
              size: 3,
              isInfo: false,
              options: "CatPaises",
              optionsIsCatalogue: true,
              optionsFilters: (data) => {
                if (!data || data.length === 0) return [];
                let tmp = [...data];
                return tmp
                  .reduce((acum, value) => {
                    const exists = acum.find((a) => a.Lada === value.Lada);
                    if (!exists) acum.push(value);
                    return acum;
                  }, [])
                  .sort((a, b) =>
                    a.Lada > b.Lada ? 1 : b.Lada > a.Lada ? -1 : 0
                  );
              },
              withFilters: true,
              valueOption: "Lada",
              showOption: (tmp) => {
                return `+${tmp.Lada}`;
              },
              required: true,
            },
            {
              key: "Telefono",
              fieldIso: "cellphone",
              type: "number",
              size: 3,
              min: 1,
              max: 12,
              isInfo: false,
              required: true,
            },
            {
              key: "LadaCelular",
              fieldIso: "codPhone",
              type: "select",
              size: 3,
              isInfo: false,
              options: "CatPaises",
              optionsIsCatalogue: true,
              optionsFilters: (data) => {
                if (!data || data.length === 0) return [];
                let tmp = [...data];
                return tmp
                  .reduce((acum, value) => {
                    const exists = acum.find((a) => a.Lada === value.Lada);
                    if (!exists) acum.push(value);
                    return acum;
                  }, [])
                  .sort((a, b) =>
                    a.Lada > b.Lada ? 1 : b.Lada > a.Lada ? -1 : 0
                  );
              },
              withFilters: true,
              valueOption: "Lada",
              showOption: (tmp) => {
                return `+${tmp.Lada}`;
              },
            },
            {
              key: "Celular",
              fieldIso: "phone",
              type: "number",
              size: 3,
              min: 1,
              max: 12,
              isInfo: false,
            },
            {
              key: "Pais",
              fieldIso: "country",
              type: "select",
              size: 3,
              isInfo: false,
              options: "CatPaises",
              optionsIsCatalogue: true,
              valueOption: "Id",
              showOption: (tmp) => {
                return this.iso === "es" ? tmp.Nombre : tmp.NombreEn;
              },
              withFilters: true,
              optionsFilters: (data) => {
                if (!data || data.length === 0) return [];
                let tmp = [...data];
                const can = tmp.find((d) => d.Id === "CAN");
                const usa = tmp.find((d) => d.Id === "USA");
                return [
                  usa,
                  can,
                  ...tmp.filter((d) => d.Id !== "USA" && d.Id !== "CAN"),
                ];
              },
              required: true,
            },
            {
              key: "Estado",
              fieldIso: "state",
              type: "select",
              size: 3,
              isInfo: false,
              options: "CatEstadosPais",
              optionsIsCatalogue: true,
              valueOption: "Id",
              optionsFilters: (data) => {
                if (!data || data.length === 0) return [];
                else
                  return data.filter((d) => d.PaisId === this.data.owner.Pais);
              },
              withFilters: true,
              showOption: (tmp) => {
                return this.iso === "es" ? tmp.Nombre : tmp.NombreEng;
              },
              required: true,
            },
            {
              key: "Ciudad",
              fieldIso: "city",
              type: "text",
              size: 3,
              isInfo: false,
              required: true,
              max: 20,
              rgx: rgxCityAndAddress[0],
              rgxIso: rgxCityAndAddress[1],
              valRgx: rgxCityAndAddress[0],
            },
            {
              key: "Direccion",
              fieldIso: "address",
              type: "text",
              size: 3,
              isInfo: false,
              required: true,
              max: 30,
              rgx: rgxCityAndAddress[0],
              rgxIso: rgxCityAndAddress[1],
              valRgx: rgxCityAndAddress[0],
            },
            {
              key: "CodigoPostal",
              fieldIso: "zip",
              type: "text",
              size: 3,
              isInfo: false,
              required: true,
              // rgx: rgxZip[0],
              rgxIso: rgxOnlyTextAndGuion[1],
              valRgx: rgxOnlyTextAndGuion[0],
              max: 12,
            },
            {
              key: "Nacionalidad",
              fieldIso: "nationality",
              type: "select",
              size: 3,
              isInfo: false,
              options: "CatPaises",
              optionsIsCatalogue: true,
              valueOption: "Id",
              showOption: (tmp) => {
                return this.iso === "es"
                  ? tmp.Nacionalidad
                  : tmp.NacionalidadEn;
              },
              withFilters: true,
              optionsFilters: (data) => {
                if (!data || data.length === 0) return [];
                let tmp = [...data];
                const can = tmp.find((d) => d.Id === "CAN");
                const usa = tmp.find((d) => d.Id === "USA");
                return [
                  usa,
                  can,
                  ...tmp.filter((d) => d.Id !== "USA" && d.Id !== "CAN"),
                ];
              },
              required: true,
            },
            {
              key: "EstadoCivil",
              fieldIso: "civilStatus",
              type: "select",
              size: 3,
              isInfo: false,
              options: "CatEstadoCivil",
              optionsIsCatalogue: true,
              valueOption: "Id",
              showOption: (tmp) => {
                return this.iso === "es" ? tmp.Nombre : tmp.NombreEn;
              },
              required: false,
            },
          ],
        },
        {
          titleIso: "coOwner",
          ref: "coOwner",
          showModule: () => {
            return true;
          },
          subtitleIso: "validate__msg3",
          info: {
            type: "is-warning is-light",
            exec: () => this.moreInfo("coOwner"),
            icon: "exclamation-circle",
            text: () => this.i18n.warning[this.iso],
            expanded: false,
          },
          list: [
            {
              key: "Nombre",
              fieldIso: "name",
              type: "text",
              size: 3,
              isInfo: false,
              required: false,
              min: 1,
              max: 20,
              rgx: rgxOnlyTextAndSpace[0],
              rgxIso: rgxOnlyTextAndSpace[1],
              valRgx: rgxOnlyTextAndSpace[0],
            },
            {
              key: "ApellidoPaterno",
              fieldIso: "lastname",
              type: "text",
              size: 3,
              isInfo: false,
              required: false,
              min: 1,
              max: 20,
              rgx: rgxOnlyTextAndSpace[0],
              rgxIso: rgxOnlyTextAndSpace[1],
              valRgx: rgxOnlyTextAndSpace[0],
            },
            {
              key: "Email",
              fieldIso: "email",
              type: "email",
              size: 3,
              isInfo: false,
              required: false,
              rgxIso: rgxEmail[1],
              max: 50,
            },
            {
              key: "FechaNacimiento",
              fieldIso: "birthdate",
              type: "date",
              size: 3,
              isInfo: false,
              required: false,
              maxDate: new Date(moment().subtract(18, "years")),
            },
            {
              key: "LadaTelefono",
              fieldIso: "codCellphone",
              type: "select",
              size: 3,
              isInfo: false,
              options: "CatPaises",
              optionsIsCatalogue: true,
              optionsFilters: (data) => {
                if (!data || data.length === 0) return [];
                let tmp = [...data];
                return tmp
                  .reduce((acum, value) => {
                    const exists = acum.find((a) => a.Lada === value.Lada);
                    if (!exists) acum.push(value);
                    return acum;
                  }, [])
                  .sort((a, b) =>
                    a.Lada > b.Lada ? 1 : b.Lada > a.Lada ? -1 : 0
                  );
              },
              withFilters: true,
              valueOption: "Lada",
              showOption: (tmp) => {
                return `+${tmp.Lada}`;
              },
              required: false,
            },
            {
              key: "Telefono",
              fieldIso: "cellphone",
              type: "number",
              size: 3,
              min: 1,
              max: 12,
              isInfo: false,
              required: false,
            },
            {
              key: "LadaCelular",
              fieldIso: "codPhone",
              type: "select",
              size: 3,
              isInfo: false,
              options: "CatPaises",
              optionsIsCatalogue: true,
              optionsFilters: (data) => {
                if (!data || data.length === 0) return [];
                let tmp = [...data];
                return tmp
                  .reduce((acum, value) => {
                    const exists = acum.find((a) => a.Lada === value.Lada);
                    if (!exists) acum.push(value);
                    return acum;
                  }, [])
                  .sort((a, b) =>
                    a.Lada > b.Lada ? 1 : b.Lada > a.Lada ? -1 : 0
                  );
              },
              withFilters: true,
              valueOption: "Lada",
              showOption: (tmp) => {
                return `+${tmp.Lada}`;
              },
            },
            {
              key: "Celular",
              fieldIso: "phone",
              type: "number",
              size: 3,
              min: 1,
              max: 12,
              isInfo: false,
            },
            {
              key: "Nacionalidad",
              fieldIso: "nationality",
              type: "select",
              size: 3,
              isInfo: false,
              options: "CatPaises",
              optionsIsCatalogue: true,
              valueOption: "Id",
              showOption: (tmp) => {
                return this.iso === "es"
                  ? tmp.Nacionalidad
                  : tmp.NacionalidadEn;
              },
              withFilters: true,
              optionsFilters: (data) => {
                if (!data || data.length === 0) return [];
                let tmp = [...data];
                const can = tmp.find((d) => d.Id === "CAN");
                const usa = tmp.find((d) => d.Id === "USA");
                return [
                  usa,
                  can,
                  ...tmp.filter((d) => d.Id !== "USA" && d.Id !== "CAN"),
                ];
              },
              required: false,
            },
            {
              key: "EstadoCivil",
              fieldIso: "civilStatus",
              type: "select",
              size: 3,
              isInfo: false,
              options: "CatEstadoCivil",
              optionsIsCatalogue: true,
              valueOption: "Id",
              showOption: (tmp) => {
                return this.iso === "es" ? tmp.Nombre : tmp.NombreEn;
              },
              required: false,
            },
          ],
        },
        {
          titleIso: "fiendsAndFamily",
          ref: "beneficiaries",
          subtitleIso: "validate__msg4",
          showModule: () => {
            return true;
          },
          isArray: true,
          addMore: () => {
            if (this.data.beneficiaries.length < 5) {
              this.data.beneficiaries.push({ ...TEMPLATE_MEMBER });
            } else {
              this.$buefy.toast.open({
                message: this.i18n.relationship__msg1[this.iso],
                type: "is-warning",
              });
            }
          },
          list: [
            {
              key: "Nombre",
              fieldIso: "name",
              type: "text",
              size: 4,
              isInfo: false,
              required: false,
              min: 1,
              max: 20,
              rgx: rgxOnlyTextAndSpace[0],
              rgxIso: rgxOnlyTextAndSpace[1],
              valRgx: rgxOnlyTextAndSpace[0],
            },
            {
              key: "ApellidoPaterno",
              fieldIso: "lastname",
              type: "text",
              size: 4,
              isInfo: false,
              required: false,
              min: 1,
              max: 20,
              rgx: rgxOnlyTextAndSpace[0],
              rgxIso: rgxOnlyTextAndSpace[1],
              valRgx: rgxOnlyTextAndSpace[0],
            },
            {
              key: "Parentesco",
              fieldIso: "relationship",
              type: "select",
              size: 3,
              isInfo: false,
              options: "CatParentescos",
              optionsIsCatalogue: true,
              valueOption: "Id",
              showOption: (tmp) => {
                return this.iso === "es" ? tmp.Nombre : tmp.NombreEn;
              },
              required: false,
            },
            {
              key: "Remove",
              fieldIso: "remove",
              type: "button",
              size: 1,
              isInfo: false,
              required: false,
              action: (idx) => {
                let tmp = [];
                for (let i in this.data.beneficiaries) {
                  if (parseInt(i) !== idx) tmp.push(this.data.beneficiaries[i]);
                }
                this.data.beneficiaries = [...tmp];
                this.$forceUpdate();
              },
            },
          ],
        },
        {
          titleIso: "membershipBenefits",
          ref: "membershipBenefits",
          showModule: () => {
            return true;
          },
          list: [
            {
              key: "VipWeeks",
              fieldIso: "vipWeeks",
              type: "select",
              size: 3,
              isInfo: false,
              required: true,
              options: OPTIONS_WEEKS_EXT.map((o) => {
                return {
                  Value: o,
                  Key: o === 99 ? "Unlimited" : o,
                };
              }),
              // OPTIONS_WEEKS.map((o) => {
              //   return {
              //     Value: o,
              //     Key: o === 99 ? "Unlimited" : o,
              //   };
              // }),
              optionsIsCatalogue: false,
              withFilters: false,
              valueOption: "Value",
              showOption: (tmp) => {
                return tmp.Key;
              },
            },
            {
              key: "SmartWeeks",
              fieldIso: "smartWeeks",
              type: "select",
              size: 3,
              isInfo: false,
              required: true,
              options: OPTIONS_WEEKS_EXT.map((o) => {
                return {
                  Value: o,
                  Key: o === 99 ? "Unlimited" : o,
                };
              }),
              optionsIsCatalogue: false,
              withFilters: false,
              valueOption: "Value",
              showOption: (tmp) => {
                return tmp.Key;
              },
            },
            {
              key: "LastMinuteWeeks",
              fieldIso: "lastMinuteWeeks",
              type: "select",
              size: 3,
              isInfo: false,
              required: true,
              options: OPTIONS_WEEKS_EXT.map((o) => {
                return {
                  Value: o,
                  Key: o === 99 ? "Unlimited" : o,
                };
              }),
              optionsIsCatalogue: false,
              withFilters: false,
              valueOption: "Value",
              showOption: (tmp) => {
                return tmp.Key;
              },
            },
          ],
        },
        {
          titleIso: "incentives",
          ref: "incentives",
          subtitleIso: "validate__msg6",
          isArray: true,
          sizeTitle: 2,
          addMore: () => {
            if (this.data.incentives.length < 5) {
              this.data.incentives.push({ ...TEMPLATE_TRAVEL_VAUCHER });
            } else {
              this.$buefy.toast.open({
                message: this.i18n.incentives__msg1[this.iso],
                type: "is-warning",
              });
            }
          },
          showModule: () =>
            this.club &&
            this.club?.Id !== "23699F31-F366-4A06-AD6C-EE4719C75A73" &&
            this.club?.ModuloTravelVoucher,
          list: [
            // {
            //   key: "Cantidad",
            //   fieldIso: "travelVoucher",
            //   type: "select",
            //   size: 3,
            //   isInfo: false,
            //   options: OPTIONS_INCENTIVES.map((o) => {
            //     return {
            //       Value: o,
            //       Key: o,
            //     };
            //   }),
            //   optionsIsCatalogue: false,
            //   valueOption: "Value",
            //   showOption: (tmp) => {
            //     return tmp.Key;
            //   },
            //   required: false,
            // },
            {
              key: "Monto",
              fieldIso: "travelVoucher",
              type: "number",
              size: 3,
              min: 1,
              isInfo: false,
              required: false,
            },
          ],
        },
        {
          titleIso: "travelVoucher",
          ref: "incentives",
          isArray: true,
          sizeTitle: 2,
          addMore: () => {
            if (this.data.incentives.length < 5) {
              this.data.incentives.push({ ...TEMPLATE_TRAVEL_VAUCHER_VR });
            } else {
              this.$buefy.toast.open({
                message: this.i18n.incentives__msg1[this.iso],
                type: "is-warning",
              });
            }
          },
          subtitleIso: "validate__msg6",
          showModule: () =>
            this.club &&
            this.club?.Id === "23699F31-F366-4A06-AD6C-EE4719C75A73",
          list: [
            {
              key: "Cantidad",
              fieldIso: "quantity",
              type: "select",
              size: 3,
              isInfo: false,
              options: [1, 2].map((o) => {
                return {
                  Value: o,
                  Key: o,
                };
              }),
              optionsIsCatalogue: false,
              valueOption: "Value",
              showOption: (tmp) => {
                return tmp.Key;
              },
              required: false,
            },
            // {
            //   key: "Monto",
            //   fieldIso: "amount",
            //   type: "number",
            //   size: 3,
            //   min: 1,
            //   isInfo: false,
            //   required: false,
            //   disabled: true,
            // },
          ],
        },
        {
          titleIso: "detailsContract",
          ref: "detailsContract",
          showModule: () => {
            return (this.club && this.club?.ModuloPagos) || false;
          },
          list: [
            {
              key: "PrecioContrato",
              fieldIso: "priceContract",
              type: "text-custom",
              size: 3,
              isInfo: false,
              required: false,
              icon: "dollar-sign",
              fClear: () => {
                this.data.detailsContract.PrecioContrato = null;
                this.$forceUpdate();
              },
              fKeyUp: (e) => {
                const n = this.formatNumberDecimals(e.target.value);
                this.data.detailsContract.PrecioContrato = n;
                this.$forceUpdate();
              },
            },
            {
              key: "CostoAdministracion",
              fieldIso: "administrationCost",
              type: "text-custom",
              size: 3,
              isInfo: false,
              required: false,
              icon: "dollar-sign",
              fClear: () => {
                this.data.detailsContract.CostoAdministracion = null;
                this.$forceUpdate();
              },
              fKeyUp: (e) => {
                const n = this.formatNumberDecimals(e.target.value);
                this.data.detailsContract.CostoAdministracion = n;
                this.$forceUpdate();
              },
            },
            {
              key: "PagoInicial",
              fieldIso: "initialPayment",
              type: "text-custom",
              size: 3,
              isInfo: false,
              required: false,
              icon: "dollar-sign",
              fClear: () => {
                this.data.detailsContract.PagoInicial = null;
                this.$forceUpdate();
              },
              fKeyUp: (e) => {
                const n = this.formatNumberDecimals(e.target.value);
                this.data.detailsContract.PagoInicial = n;
                this.$forceUpdate();
              },
            },
            {
              key: "PorcentajePagoInicial",
              fieldIso: "downPaymentPercentage",
              type: "text-custom",
              size: 3,
              isInfo: false,
              required: false,
              icon: "percentage",
              max: 4,
              fClear: () => {
                this.data.detailsContract.PorcentajePagoInicial = null;
                this.$forceUpdate();
              },
              fKeyUp: (e) => {
                const n = this.formatNumberDecimals(e.target.value);
                this.data.detailsContract.PorcentajePagoInicial = n;
                this.$forceUpdate();
              },
              // fInput: (v, k) => this.calcularPagos(v, k),
            },
          ],
        },
        {
          titleIso: "closingCost",
          sizeTitle: 2,
          ref: "conditionalDownPayment",
          showModule: () => {
            return (
              (this.club && this.club?.ModuloPagos && this.club?.ModuloCDP) ||
              false
            );
          },
          // subtitleIso: "validate__msg4",
          isArray: true,
          addMore: () => {
            if (this.data.conditionalDownPayment.length < 3) {
              this.data.conditionalDownPayment.push({
                FechaPago: null,
                CargoPago: 0,
                TipoPago: "CDP",
              });
            } else {
              this.$buefy.toast.open({
                message: this.i18n.conditionalDownPayment__msg1[this.iso],
                type: "is-warning",
              });
            }
          },
          list: [
            {
              key: "CargoPago",
              fieldIso: "amount",
              type: "text-custom",
              size: 3,
              isInfo: false,
              required: false,
              icon: "dollar-sign",
              fClear: (idx) => {
                this.data.conditionalDownPayment[idx].CargoPago = 0;
                this.$forceUpdate();
              },
              fKeyUp: (item, idx) => {
                const n = this.formatNumberDecimals(item);
                this.data.conditionalDownPayment[idx].CargoPago = n;
                this.$forceUpdate();
              },
            },
            {
              key: "FechaPago",
              fieldIso: "date",
              type: "date",
              size: 3,
              isInfo: false,
              required: false,
            },
            {
              key: "Remove",
              fieldIso: "remove",
              type: "button",
              size: 1,
              isInfo: false,
              required: false,
              action: (idx) => {
                let tmp = [];
                for (let i in this.data.conditionalDownPayment) {
                  if (parseInt(i) !== idx)
                    tmp.push(this.data.conditionalDownPayment[i]);
                }
                this.data.conditionalDownPayment = [...tmp];
                this.$forceUpdate();
              },
            },
          ],
        },
        {
          titleIso: "authorizationPayments",
          sizeTitle: 5,
          ref: "additionalPayments",
          // showModule: () => {
          //   return (
          //     (this.club && this.club?.ModuloPagos && this.club?.ModuloADP) ||
          //     false
          //   );
          // },
          showModule: () => false,
          // this.club &&
          // this.club?.Id === "24C6D42E-9A7A-41EA-BF35-7B81FD4D2343" &&
          // this.club?.ModuloPagos &&
          // this.club?.ModuloADP,
          // subtitleIso: "validate__msg4",
          isArray: true,
          addMore: () => {
            if (this.data.additionalPayments.length < 3) {
              this.data.additionalPayments.push({
                FechaPago: null,
                CargoPago: 0,
                TipoPago: "ADP",
              });
            } else {
              this.$buefy.toast.open({
                message: this.i18n.additionalPayments__msg1[this.iso],
                type: "is-warning",
              });
            }
          },
          list: [
            {
              key: "CargoPago",
              fieldIso: "amount",
              type: "text-custom",
              size: 3,
              isInfo: false,
              required: false,
              icon: "dollar-sign",
              fClear: (idx) => {
                this.data.additionalPayments[idx].CargoPago = 0;
                this.$forceUpdate();
              },
              fKeyUp: (item, idx) => {
                const n = this.formatNumberDecimals(item);
                this.data.additionalPayments[idx].CargoPago = n;
                this.$forceUpdate();
              },
            },
            {
              key: "FechaPago",
              fieldIso: "date",
              type: "date",
              size: 3,
              isInfo: false,
              required: false,
            },
            {
              key: "Remove",
              fieldIso: "remove",
              type: "button",
              size: 1,
              isInfo: false,
              required: false,
              action: (idx) => {
                let tmp = [];
                for (let i in this.data.additionalPayments) {
                  if (parseInt(i) !== idx)
                    tmp.push(this.data.additionalPayments[i]);
                }
                this.data.additionalPayments = [...tmp];
                this.calcularPagos(null, null);
                this.$forceUpdate();
              },
            },
          ],
        },
        {
          titleIso: "adp",
          sizeTitle: 1,
          ref: "additionalPayments",
          // showModule: () => {
          //   return (
          //     (this.club && this.club?.ModuloPagos && this.club?.ModuloADP) ||
          //     false
          //   );
          // },
          showModule: () =>
            this.club && this.club?.ModuloPagos && this.club?.ModuloADP,
          // subtitleIso: "validate__msg4",
          isArray: true,
          addMore: () => {
            if (this.data.additionalPayments.length < 3) {
              this.data.additionalPayments.push({
                FechaPago: null,
                CargoPago: 0,
                TipoPago: "ADP",
              });
            } else {
              this.$buefy.toast.open({
                message: this.i18n.additionalPayments__msg1[this.iso],
                type: "is-warning",
              });
            }
          },
          list: [
            {
              key: "CargoPago",
              fieldIso: "amount",
              type: "text-custom",
              size: 3,
              isInfo: false,
              required: false,
              icon: "dollar-sign",
              fClear: (idx) => {
                this.data.additionalPayments[idx].CargoPago = 0;
                this.$forceUpdate();
              },
              fKeyUp: (item, idx) => {
                const n = this.formatNumberDecimals(item);
                this.data.additionalPayments[idx].CargoPago = n;
                this.$forceUpdate();
              },
            },
            {
              key: "FechaPago",
              fieldIso: "date",
              type: "date",
              size: 3,
              isInfo: false,
              required: false,
            },
            {
              key: "Remove",
              fieldIso: "remove",
              type: "button",
              size: 1,
              isInfo: false,
              required: false,
              action: (idx) => {
                let tmp = [];
                for (let i in this.data.additionalPayments) {
                  if (parseInt(i) !== idx)
                    tmp.push(this.data.additionalPayments[i]);
                }
                this.data.additionalPayments = [...tmp];
                this.$forceUpdate();
              },
            },
          ],
        },
        {
          titleIso: "detailsContract",
          ref: "detailsContract",
          titleHide: true,
          showModule: () => {
            return (this.club && this.club?.ModuloPagos) || false;
          },
          list: [
            {
              key: "Saldo",
              fieldIso: "balance",
              type: "text-custom",
              size: 3,
              isInfo: false,
              required: false,
              icon: "dollar-sign",
              fClear: () => {
                this.data.detailsContract.Saldo = null;
                this.$forceUpdate();
              },
              fKeyUp: (e) => {
                const n = this.formatNumberDecimals(e.target.value);
                this.data.detailsContract.Saldo = n;
                this.$forceUpdate();
              },
              disabled: true,
            },
            // {
            //   key: "Mensualidades",
            //   fieldIso: "monthlyPayments",
            //   type: "number",
            //   size: 3,
            //   isInfo: false,
            //   required: false,
            // },
            {
              key: "Mensualidades",
              fieldIso: "monthlyPayments",
              type: "select",
              size: 3,
              isInfo: false,
              options: () => {
                return this.club__Mensualidades.reduce(
                  (acum, value, idx, arr) => {
                    if (idx === 1) {
                      const [acum, max] = arr;
                      let tmp = [];
                      for (let i = acum; i <= max; i += acum)
                        tmp.push({ Key: i, Value: i });
                      return tmp;
                    }
                  },
                  []
                );
              },
              optionsExec: true,
              optionsIsCatalogue: false,
              valueOption: "Value",
              showOption: (tmp) => {
                return tmp.Key;
              },
              required: false,
            },
            {
              key: "TasaInteres",
              fieldIso: "interestRate",
              type: "text-custom",
              size: 3,
              isInfo: false,
              required: false,
              icon: "percentage",
              max: 4,
              fClear: () => {
                this.data.detailsContract.TasaInteres = null;
                this.$forceUpdate();
              },
              fKeyUp: (e) => {
                const n = this.formatNumberDecimals(e.target.value);
                this.data.detailsContract.TasaInteres = n;
                this.$forceUpdate();
              },
            },
            {
              key: "FactorIA",
              fieldIso: "MonthlyInterestRate",
              type: "text-custom",
              size: 3,
              isInfo: false,
              required: false,
              icon: "percentage",
              max: 4,
              fClear: () => {
                this.data.detailsContract.FactorIA = null;
                this.$forceUpdate();
              },
              fKeyUp: (e) => {
                const n = this.formatNumberDecimals(e.target.value);
                this.data.detailsContract.FactorIA = n;
                this.$forceUpdate();
              },
              disabled: true,
            },
            {
              key: "PagoMensual",
              fieldIso: "monthlyPayment",
              type: "text-custom",
              size: 3,
              isInfo: false,
              required: false,
              icon: "dollar-sign",
              fClear: () => {
                this.data.detailsContract.PagoMensual = null;
                this.$forceUpdate();
              },
              fKeyUp: (e) => {
                const n = this.formatNumberDecimals(e.target.value);
                this.data.detailsContract.PagoMensual = n;
                this.$forceUpdate();
              },
              disabled: true,
            },
            {
              key: "FechaPrimeraMensualidad",
              fieldIso: "firstMonthlyDate",
              type: "date",
              size: 3,
              isInfo: false,
              required: false,
              maxDate: new Date(moment().add(10, "years")),
            },
          ],
        },
      ],
    };
  },
  async created() {
    this.iso = localStorage.getItem("iso");
    this.$bus.on("change-iso", () => {
      this.iso = localStorage.getItem("iso");
    });

    this.$bus.on("change-club", () => {
      this.getClub();
    });

    await this.getCatalogues();
    await this.getClub();

    if (this.club && this.club?.ModuloPagos__Mensualidades)
      this.club__Mensualidades = this.club?.ModuloPagos__Mensualidades;

    if (this.club && this.club?.Id === "23699F31-F366-4A06-AD6C-EE4719C75A73") {
      this.data.incentives.Monto = 399;
    }
  },
  methods: {
    async getCatalogues() {
      try {
        // this.loading = true;

        const promises = [];

        for (let cat of listCatalogues) {
          promises.push(
            axios
              .get(`${API_URL}/catalogue/${cat}`)
              .then(({ data }) => {
                this.catalogues[cat] = data;
              })
              .catch((err) => {
                console.log(err);
                this.$buefy.toast.open({
                  message: "Error al consultar datos de catalogos",
                  type: "is-danger",
                });
              })
          );
          // const { data } = await axios.get(`${API_URL}/catalogue/${cat}`);
          // this.catalogues[cat] = data;
        }

        await Promise.all(promises);

        this.$forceUpdate();

        // console.log("catalogues", this.catalogues);
        // this.loading = false;
      } catch (err) {
        // this.loading = false;
        console.log(err);
        this.$buefy.toast.open({
          message: "Error al consultar datos de catalogos",
          type: "is-danger",
        });
      }
    },
    sliderType(a) {
      if (this.sliderValue > 25 && this.sliderValue < 75) {
        return "is-warning";
      } else if (this.sliderValue >= 75) {
        return "is-success";
      }
      return "is-danger";
    },
    beforeCreateAll() {
      let __data = [
        `<b>${this.i18n.validateMemberData__mgs1[this.iso]}...</b>`,
      ];

      for (let item of this.items) {
        //Titulares
        if (item.ref === "owner" || item.ref === "coOwner") {
          __data.push(
            `<br><b>${this.i18n[item.titleIso][this.iso]
              .toString()
              .toUpperCase()}</b><br>`
          );
          for (let l of item.list) {
            if (
              l.type === "text" ||
              l.type === "email" ||
              l.key === "LadaTelefono" ||
              l.key === "Telefono"
            ) {
              __data.push(
                `${this.i18n[l.fieldIso][this.iso]}: <b>${
                  this.data[item.ref][l.key] ? this.data[item.ref][l.key] : ""
                }</b>`
              );
            }
          }
        }
      }

      __data = __data.join("<br>");
      this.$buefy.dialog.confirm({
        title: this.i18n.validateMemberData[this.iso],
        message: __data,
        cancelText: this.i18n.correct[this.iso],
        confirmText: this.i18n.continue[this.iso],
        type: "is-info",
        onConfirm: async () => await this.createAll(),
      });
    },
    async createAll() {
      console.log(this.data);
      this.loading = true;
      const { pass, message, passCoOwner, passBeneficiaries, passIncentives } =
        await this.validateAll();
      this.loading = false;
      if (!pass) {
        this.$buefy.dialog.alert({
          title: this.i18n.validationError[this.iso],
          message,
          type: "is-danger",
          hasIcon: true,
          icon: "times-circle",
          iconPack: "fa",
          ariaRole: "alertdialog",
          ariaModal: true,
        });
      } else if (passCoOwner > 0 && passCoOwner < 2) {
        this.$buefy.dialog.alert({
          title: this.i18n.validationError[this.iso],
          message: this.i18n.validate__msg3[this.iso],
          type: "is-danger",
          hasIcon: true,
          icon: "times-circle",
          iconPack: "fa",
          ariaRole: "alertdialog",
          ariaModal: true,
        });
      } else if (passBeneficiaries > 0 && passBeneficiaries < 3) {
        this.$buefy.dialog.alert({
          title: this.i18n.validationError[this.iso],
          message: this.i18n.validate__msg4__ext1[this.iso],
          type: "is-danger",
          hasIcon: true,
          icon: "times-circle",
          iconPack: "fa",
          ariaRole: "alertdialog",
          ariaModal: true,
        });
      } else if (passIncentives !== 0 && passIncentives % 2 !== 0) {
        this.$buefy.dialog.alert({
          title: this.i18n.validationError[this.iso],
          message: this.i18n.validate__msg6[this.iso],
          type: "is-danger",
          hasIcon: true,
          icon: "times-circle",
          iconPack: "fa",
          ariaRole: "alertdialog",
          ariaModal: true,
        });
      } else {
        this.loading = true;
        const { Id, Idioma, EstadoContrato, LoginID } =
          await this.createContract(
            passIncentives !== 0 && passIncentives % 2 === 0
          );
        console.log("contract", Id, Idioma, EstadoContrato, LoginID);

        const __owner = await this.createOwner(Id, Idioma, LoginID);

        if (passCoOwner === 2) await this.createCoOwner(Id, Idioma, LoginID);

        if (passBeneficiaries === 3)
          await this.createBeneficiary(Id, Idioma, LoginID);

        if (EstadoContrato === 2) {
          await this.editStatusContract(Id, EstadoContrato);

          // if (this.club && this.club?.ReplicarGenerico)
          //   await this.createGenerico(Id);
        }

        this.loading = false;
        this.$buefy.dialog.alert({
          title: this.i18n.successfulOperation[this.iso],
          message: this.i18n.okCreateAllCap[this.iso],
          type: "is-success",
          hasIcon: true,
          icon: "check-circle",
          iconPack: "fa",
          ariaRole: "alertdialog",
          ariaModal: true,
          onConfirm: () =>
            this.$router.push(`/profile-member?id=${__owner._id}`),
        });
      }
    },
    async validateAll() {
      try {
        console.log("data", this.data);
        let pass = true;
        let message = "";
        for (let item of this.items) {
          if (item.ref !== "beneficiaries") {
            for (let l of item.list) {
              if (
                l.required &&
                (!this.data[item.ref][l.key] ||
                  this.data[item.ref][l.key] === "")
              ) {
                pass = false;
                message = this.i18n.validate__msg1[this.iso].replace(
                  "{{field}}",
                  this.i18n[l.fieldIso][this.iso]
                );
              }
              if (
                l.min &&
                l.type === "text" &&
                this.data[item.ref][l.key] &&
                (l.key === "Nombre" || l.key === "ApellidoPaterno") &&
                `${this.data[item.ref][l.key]}`.length < l.min
              ) {
                pass = false;
                message = this.i18n.validate__msg5[this.iso].replace(
                  "{{field}}",
                  this.i18n[l.fieldIso][this.iso]
                );
              }

              if (
                l.min &&
                l.type === "number" &&
                this.data[item.ref][l.key] !== null &&
                `${this.data[item.ref][l.key]}` < l.min
              ) {
                pass = false;
                message = this.i18n.validate__msg7[this.iso].replace(
                  "{{field}}",
                  this.i18n[l.fieldIso][this.iso]
                );
              }

              let validateRgxs = [
                "Numero",
                "Nombre",
                "ApellidoPaterno",
                "Email",
                "Ciudad",
                "Direccion",
                "CodigoPostal",
              ];
              if (validateRgxs.includes(l.key) && this.data[item.ref][l.key]) {
                const __rgx = new RegExp(l.valRgx);
                if (!__rgx.test(this.data[item.ref][l.key])) {
                  pass = false;
                  message = `${this.i18n[l.fieldIso][this.iso]} (${
                    this.i18n[item.titleIso][this.iso]
                  }) - ${this.i18n[l.rgxIso][this.iso]}`;
                  // message = this.i18n.validate__msg5[this.iso].replace(
                  //   "{{field}}",
                  //   this.i18n[l.fieldIso][this.iso]
                  // );
                }
              }
            }
          }
        }

        /**
         * Validar el cotitular
         */
        let passCoOwner = 0;
        for (let i in this.data.coOwner) {
          if (
            (i === "Nombre" || i === "ApellidoPaterno") /*|| i === "Email"*/ &&
            this.data.coOwner[i] &&
            this.data.coOwner[i] !== ""
          ) {
            passCoOwner++;
          }
        }

        /**
         * Validar los beneficiarios
         */
        let passBeneficiaries = 0;
        let finishValidateBeneficiaries = false;
        for (let beneficiary of this.data.beneficiaries) {
          if (passBeneficiaries === 3) passBeneficiaries = 0;
          for (let i in beneficiary) {
            const __rgx = new RegExp(rgxOnlyTextAndSpace[0]);
            if (
              (i === "Nombre" || i === "ApellidoPaterno") &&
              beneficiary[i] &&
              __rgx.test(beneficiary[i]) &&
              beneficiary[i] !== "" &&
              !finishValidateBeneficiaries
            ) {
              passBeneficiaries++;
            }
            if (
              i === "Parentesco" &&
              beneficiary[i] &&
              beneficiary[i] !== "" &&
              !finishValidateBeneficiaries
            ) {
              passBeneficiaries++;
            }
          }
          if (passBeneficiaries > 0 && passBeneficiaries < 3)
            finishValidateBeneficiaries = true;
        }

        /**
         * Travel Vaucher
         */
        let passIncentives = 0;
        // for (let i in this.data.incentives) {
        //   if (
        //     (i === "Cantidad" || i === "Monto") &&
        //     this.data.incentives[i] &&
        //     this.data.incentives[i] !== ""
        //   ) {
        //     passIncentives++;
        //   }
        // }
        for (let incentives of this.data.incentives) {
          for (let i in incentives) {
            if ((i === "Cantidad" || i === "Monto") && incentives[i]) {
              passIncentives++;
            }
          }
        }

        /**
         * Validamos email repetido
         */

        if (this.data.owner?.Email === this.data.coOwner?.Email) {
          pass = false;
          message = this.i18n.email__err1[this.iso];
        }

        /**
         * Validamos email ya creados
         */

        let isCreatedEmailOwner = await this.validateEmail(
          this.data.owner?.Email
        );
        if (!isCreatedEmailOwner) {
          pass = false;
          message = this.i18n.email__err2[this.iso].replace(
            "{{email}}",
            this.data.owner?.Email
          );
        }

        let isCreatedEmailCoOwner = await this.validateEmail(
          this.data.coOwner?.Email
        );
        if (!isCreatedEmailCoOwner) {
          pass = false;
          message = this.i18n.email__err2[this.iso].replace(
            "{{email}}",
            this.data.coOwner?.Email
          );
        }

        /**
         * Validamos generico
         */
        if (this.club && this.club?.ReplicarGenerico) {
          let checkGen = await this.validateUserGenerico(
            this.data?.contract?.LoginID,
            this.data?.contract?.Numero,
            this.data.owner?.Email,
            this.data.coOwner?.Email
          );
          if (!checkGen?.pass) {
            pass = false;
            if (checkGen?.field === "EmailT") {
              message = this.i18n.email__err2[this.iso].replace(
                "{{email}}",
                this.data.owner?.Email
              );
            }
            if (checkGen?.field === "EmailC") {
              message = this.i18n.email__err2[this.iso].replace(
                "{{email}}",
                this.data.coOwner?.Email
              );
            }
            if (checkGen?.field === "Numero") {
              message = this.i18n.validate__msg8_numberContract[this.iso];
            }
            if (checkGen?.field === "LoginID") {
              message = this.i18n.validate__msg9_loginId[this.iso];
            }
          }
        }

        /**
         * Validamos el Saldo si aplica
         */

        if (this.SaldoNumber !== null && this.SaldoNumber < 0) {
          pass = false;
          message = this.i18n.validate__msg10_saldo[this.iso];
        }

        /**
         * Validamos ADP
         */

        if (this.data.conditionalDownPayment.length > 0) {
          let tmpPass = this.data.conditionalDownPayment.reduce(
            (acum, value) => {
              if (!value?.CargoPago || !value?.FechaPago) return false;
              else return true;
            },
            true
          );

          if (!tmpPass) {
            pass = false;
            message = this.i18n.validate__msg11_cdp[this.iso];
          }
        }

        if (this.data.additionalPayments.length > 0) {
          let tmpPass = this.data.additionalPayments.reduce((acum, value) => {
            if (!value?.CargoPago || !value?.FechaPago) return false;
            else return true;
          }, true);

          if (!tmpPass) {
            pass = false;
            message = this.i18n.validate__msg12_adp[this.iso];
          }
        }

        return {
          pass,
          message,
          passCoOwner,
          passBeneficiaries,
          passIncentives,
        };
      } catch (error) {
        return { pass: false, message: this.i18n.validationError[this.iso] };
      }
    },
    clearObjectData(obj) {
      let tmp = {};
      for (let i in obj) {
        if (obj[i] !== null) tmp[i] = obj[i];
      }
      return tmp;
    },
    parseDetailsContract(obj) {
      let tmp = {};
      for (let i in obj) {
        if (i !== "FechaPrimeraMensualidad" && obj[i]) {
          let parts = obj[i].toString().split(".");
          let v = parts[0].replace(/\D/g, ""),
            dec = parts[1];
          let num = Number(dec !== undefined ? v + "." + dec : v);
          tmp[i] = num;
        } else {
          tmp[i] = obj[i];
        }
      }
      return tmp;
    },
    parseAditionalPayments(arr) {
      let tmp = [];

      for (let adp of arr) {
        let tmpObj = {};
        for (let i in adp) {
          if (i !== "FechaPago" && i !== "TipoPago") {
            let parts = adp[i].split(".");
            let v = parts[0].replace(/\D/g, ""),
              dec = parts[1];
            let num = Number(dec !== undefined ? v + "." + dec : v);
            tmpObj[i] = num;
          } else {
            tmpObj[i] = adp[i];
          }
        }
        tmp.push(tmpObj);
      }

      return tmp;
    },
    async createContract(insertIncentives = false) {
      try {
        const years =
          this.data.contract.Years > 80 ? 99 : this.data.contract.Years;
        let payload = {
          ...this.DEFAULT_PARAMS,
          ...this.clearObjectData(this.data.contract),
          ...this.clearObjectData(this.data.membershipBenefits),
          FechaExpiracion: moment(this.data.contract.FechaCompra).add(
            years,
            "years"
          ),
          Seal: this.SEAL,
          Years: years,
        };

        if (insertIncentives) {
          payload = {
            ...payload,
            detailsContract: {
              ...this.parseDetailsContract(this.data.detailsContract),
              pagosAdicionales: this.parseAditionalPayments([
                ...this.data.conditionalDownPayment,
                ...this.data.additionalPayments,
              ]),
              TravelVoucher: [
                ...this.data.incentives.map((tv) => {
                  return {
                    Monto: parseInt(tv?.Monto || 0),
                    Cantidad: tv?.Cantidad || 0,
                  };
                }),
                // {
                //   Monto: parseInt(this.data.incentives.Monto),
                //   Cantidad: this.data.incentives.Cantidad,
                // },
              ],
            },
          };
        } else {
          payload = {
            ...payload,
            detailsContract: {
              ...this.parseDetailsContract(this.data.detailsContract),
              pagosAdicionales: this.parseAditionalPayments([
                ...this.data.conditionalDownPayment,
                ...this.data.additionalPayments,
              ]),
            },
          };
        }

        const { data } = await axios.post(
          `${API_URL}/catcher/contract/create`,
          { ...payload, CreadoPor: localStorage.getItem("user") }
        );

        // this.loading = false;

        return data?.contract;
      } catch (err) {
        this.loading = false;
        console.log(err);
        this.$buefy.toast.open({
          message: this.i18n.errorGeneric[this.iso],
          type: "is-danger",
        });
      }
    },
    async createOwner(contractId, Idioma, loginId) {
      try {
        const payload = {
          ContratoId: contractId,
          ...this.clearObjectData(this.data.owner),
          Seal: this.SEAL,
          Idioma,
          TipoSocio: "T",
          Password: loginId,
        };

        const { data } = await axios.post(
          `${API_URL}/catcher/members/create`,
          payload
        );
        return data.member;
      } catch (err) {
        this.loading = false;
        console.log(err);
        this.$buefy.toast.open({
          message: this.i18n.errorGeneric[this.iso],
          type: "is-danger",
        });
      }
    },
    async createCoOwner(contractId, Idioma, loginId) {
      try {
        const payload = {
          ContratoId: contractId,
          ...this.clearObjectData(this.data.coOwner),
          Seal: this.SEAL,
          Idioma,
          TipoSocio: "C",
          Password: loginId,
          Pais: this.data.owner.Pais,
          Estado: this.data.owner.Estado,
          Ciudad: this.data.owner.Ciudad,
          Direccion: this.data.owner.Direccion,
          CodigoPostal: this.data.owner.CodigoPostal,
        };

        await axios.post(`${API_URL}/catcher/members/create`, payload);
      } catch (err) {
        this.loading = false;
        console.log(err);
        this.$buefy.toast.open({
          message: this.i18n.errorGeneric[this.iso],
          type: "is-danger",
        });
      }
    },
    async createBeneficiary(contractId, Idioma, loginId) {
      try {
        for (let beneficiary of this.data.beneficiaries) {
          if (
            beneficiary.Nombre &&
            beneficiary.ApellidoPaterno &&
            beneficiary.Parentesco
          ) {
            const payload = {
              ContratoId: contractId,
              ...this.clearObjectData(beneficiary),
              Seal: this.SEAL,
              Idioma,
              TipoSocio: "F",
              Password: loginId,
            };

            await axios.post(`${API_URL}/catcher/members/create`, payload);
          }
        }
      } catch (err) {
        this.loading = false;
        console.log(err);
        this.$buefy.toast.open({
          message: this.i18n.errorGeneric[this.iso],
          type: "is-danger",
        });
      }
    },
    moreInfo(type) {
      if (type === "coOwner") {
        this.$buefy.dialog.alert({
          title: this.i18n.validate__msg3[this.iso],
          message: this.i18n.validate__msg3[this.iso],
          type: "is-warning",
          hasIcon: true,
          icon: "exclamation-circle",
          iconPack: "fas",
          ariaRole: "alertdialog",
          ariaModal: true,
        });
      }
    },
    async editStatusContract(contractId, status) {
      try {
        await axios.put(
          `${API_URL}/catcher/edit-status/contract/${contractId}?status=${status}&updatedBy=${localStorage.getItem(
            "user"
          )}`
        );
      } catch (err) {
        console.log(err);
        this.loading = false;
        this.$buefy.toast.open({
          message: "Error al editar contrato",
          type: "is-danger",
        });
      }
    },
    async validateEmail(email) {
      try {
        if (!email) return true;
        else {
          const { data } = await axios.post(
            `${API_URL}/catcher/members/list-all`,
            { Email: email }
          );
          if (data.members && data.members.length > 0) return false;
          else return true;
        }
      } catch (err) {
        console.log(err);
        this.loading = false;
        this.$buefy.toast.open({
          message: "Error al consultar email",
          type: "is-danger",
        });
      }
    },
    toLowerCase(__ref, __key) {
      if (this.data[__ref][__key])
        this.data[__ref][__key] = this.data[__ref][__key]
          .toString()
          .toLowerCase();
    },
    getClub() {
      let club = localStorage.getItem("club");
      club = club ? JSON.parse(club) : {};
      this.club = club;
      // __club = club;

      this.DEFAULT_PARAMS = {
        ClubId: club?.Id || null,
        ...this.DEFAULT_PARAMS,
        Tarifa: club?.Tarifa || 199,
      };
    },
    async createGenerico(contractId) {
      try {
        await axios.post(`${API_URL}/catcher/create/generico/${contractId}`);
      } catch (err) {
        console.log(err);
        this.loading = false;
        this.$buefy.toast.open({
          message: "Error al crear contrato en generico",
          type: "is-danger",
        });
      }
    },
    calcularPagos(value, key) {
      if (this.data.detailsContract.PrecioContrato && key === "PagoInicial") {
        let PrecioContrato = parseFloat(
          this.parseIntNumberDecimals(this.data.detailsContract.PrecioContrato)
        );
        let PagoInicial = parseFloat(
          this.parseIntNumberDecimals(this.data.detailsContract.PagoInicial)
        );

        this.data.detailsContract.PorcentajePagoInicial =
          this.formatNumberDecimals(
            ((PagoInicial / PrecioContrato) * 100).toFixed(2)
          );
        this.$forceUpdate();
      }

      if (
        this.data.detailsContract.PrecioContrato &&
        key === "PorcentajePagoInicial"
      ) {
        let PrecioContrato = parseFloat(
          this.parseIntNumberDecimals(this.data.detailsContract.PrecioContrato)
        );
        let PorcentajePagoInicial = parseFloat(
          this.parseIntNumberDecimals(
            this.data.detailsContract.PorcentajePagoInicial
          )
        );

        this.data.detailsContract.PagoInicial = this.formatNumberDecimals(
          (PorcentajePagoInicial / 100) * PrecioContrato
        );
        this.$forceUpdate();
      }

      if (this.data.detailsContract.PrecioContrato) {
        let PrecioContrato = parseFloat(
          this.parseIntNumberDecimals(this.data.detailsContract.PrecioContrato)
        );
        let CostoAdministracion = parseFloat(
          this.parseIntNumberDecimals(
            this.data.detailsContract.CostoAdministracion || 0
          )
        );
        let PagoInicial = parseFloat(
          this.parseIntNumberDecimals(
            this.data.detailsContract.PagoInicial || 0
          )
        );
        // let cdps = this.data.conditionalDownPayment.reduce((acum, value) => {
        //   let CargoPago = parseFloat(
        //     this.parseIntNumberDecimals(value.CargoPago || 0)
        //   );
        //   acum += CargoPago;
        //   return acum;
        // }, 0);
        let cdps = 0;
        let adps = this.data.additionalPayments.reduce((acum, value) => {
          let CargoPago = parseFloat(
            this.parseIntNumberDecimals(value.CargoPago || 0)
          );
          acum += CargoPago;
          return acum;
        }, 0);
        this.SaldoNumber = PrecioContrato - PagoInicial - cdps - adps;
        if (this.SaldoNumber <= 0) {
          this.data.detailsContract.Saldo = 0;
          this.data.detailsContract.TasaInteres = 0;
          this.data.detailsContract.Mensualidades = 0;
          this.data.detailsContract.FactorIA = 0;
          this.data.detailsContract.PagoMensual = 0;
          // this.data.detailsContract.FechaPrimeraMensualidad = moment()
        }

        if (this.SaldoNumber < 0) {
          this.data.detailsContract.Saldo = this.SaldoNumber;
        } else {
          this.data.detailsContract.Saldo = this.formatNumberDecimals(
            PrecioContrato - PagoInicial - cdps - adps
          );
        }

        this.$forceUpdate();
      }

      if (this.data.detailsContract.TasaInteres) {
        let TasaInteres = parseFloat(
          this.parseIntNumberDecimals(
            this.data.detailsContract.TasaInteres || 0
          )
        );

        this.data.detailsContract.FactorIA = this.formatNumberDecimals(
          ((Math.pow(1 + TasaInteres / 100, 1 / 12) - 1) * 100).toFixed(4)
        );
      }

      if (
        this.data.detailsContract.Mensualidades &&
        this.data.detailsContract.FactorIA
      ) {
        let Saldo = parseFloat(
          this.parseIntNumberDecimals(this.data.detailsContract.Saldo || 0)
        );

        let Mensualidades = parseFloat(
          this.parseIntNumberDecimals(
            this.data.detailsContract.Mensualidades || 0
          )
        );

        let FactorIA = parseFloat(
          this.parseIntNumberDecimals(this.data.detailsContract.FactorIA || 0)
        );
        FactorIA = FactorIA / 100;

        // console.log("Saldo", Saldo);
        // console.log("Mensualidades", Mensualidades);
        // console.log("FactorIA", FactorIA);

        let a = FactorIA * Math.pow(1 + FactorIA, Mensualidades);
        let b = Math.pow(1 + FactorIA, Mensualidades) - 1;
        // a = !a ? 1 : a
        // b = !b ? 1 : b
        let ab = a / b;

        // console.log("a", a);
        // console.log("b", b);

        if (ab > 0) {
          this.data.detailsContract.PagoMensual = this.formatNumberDecimals(
            (Saldo * (a / b)).toFixed(2)
          );
        } else {
          this.data.detailsContract.PagoMensual = this.formatNumberDecimals(
            (Saldo / Mensualidades).toFixed(2)
          );
        }
      }
    },
    formatNumberDecimals(value) {
      let parts = value.toString().split(".");
      let v = parts[0].replace(/\D/g, ""),
        dec = parts[1];
      let n = new Intl.NumberFormat("en-EN").format(v);
      n = dec !== undefined ? n + "." + dec : n;
      return n;
    },
    parseIntNumberDecimals(value) {
      let parts = value.toString().split(".");
      let v = parts[0].replace(/\D/g, ""),
        dec = parts[1];
      let calc_num = Number(dec !== undefined ? v + "." + dec : v);
      return calc_num;
    },
    async validateUserGenerico(loginId, numberContract, emailT, emailC) {
      try {
        const { data } = await axios.post(
          `${API_URL}/catcher/contract-check-before`,
          { loginId, numberContract, emailT, emailC }
        );
        return data;
      } catch (err) {
        console.log(err);
        this.loading = false;
        this.$buefy.toast.open({
          message: "Error al validar datos generico",
          type: "is-danger",
        });
      }
    },
  },
};
</script>

<style>
.select select option:disabled {
  cursor: not-allowed;
  opacity: 0.5;
  color: #acacac;
}
</style>
