<template>
  <div>
    <section class="hero" id="test">
      <div class="hero-body">
        <!-- <b-icon
            style="cursor: pointer; margin-top: 10px; margin-left: 20px"
            pack="fas"
            icon="arrow-left"
            size="is-small"
          >
          </b-icon> -->
        <div class="columns">
          <div class="column is-2">
            <b-button
              icon-left="arrow-left"
              icon-pack="fas"
              type="is-info is-light"
              @click="$router.go(-1)"
              expanded
              >{{ i18n.goBack[iso] }}</b-button
            >
          </div>
          <div class="column is-3">
            <p class="title">{{ i18n.memberProfile[iso] }}</p>
          </div>
        </div>

        <!-- <p class="subtitle">Hero subtitle</p> -->
        <!-- <div
            style="display: flex; align-items: center; justify-content: center"
          >
            <b-button
              style="position: relative; bottom: 5%"
              type="is-link is-light"
              icon-left="arrow-left"
              icon-pack="fas"
            >
              {{ i18n.hide[iso] }}
            </b-button>
          </div> -->
      </div>
    </section>
    <section style="margin-top: -90px">
      <div class="card article">
        <div class="card-content">
          <div class="media">
            <div class="media-center">
              <img
                src="img/profile.png"
                class="author-image"
                alt="Placeholder image"
              />
            </div>
            <div class="media-content has-text-centered">
              <p class="title article-title">
                <b>{{ member.Nombre }} {{ member.ApellidoPaterno }}</b
                ><small
                  ><span v-if="coOwner.Nombre && coOwner.Nombre !== ''"
                    >, {{ coOwner.Nombre }} {{ coOwner.ApellidoPaterno }}</span
                  ></small
                >
              </p>
              <p class="subtitle is-6 article-subtitle">
                <b-tag
                  >LoginID: <b>{{ contract.LoginID }}</b></b-tag
                >
                <b-tag style="margin-left: 10px"
                  >Nro. {{ i18n.contract[iso] }}:
                  <b>{{
                    (contract.Numero && contract.Numero.toUpperCase()) || ""
                  }}</b></b-tag
                >
                <!-- <b-tag style="margin-left: 10px"
                    >{{ i18n.coOwner[iso] }}:
                    <b
                      >{{ coOwner.Nombre }} {{ coOwner.ApellidoPaterno }}</b
                    ></b-tag
                  > -->
                <b-tag style="margin-left: 10px"
                  >Club: <b>{{ club.Nombre }}</b></b-tag
                >
              </p>
            </div>
          </div>
          <b-tabs
            v-model="activeTab"
            position="is-centered"
            type="is-boxed"
            @input="actionTab"
          >
            <b-tab-item>
              <template #header>
                <b-icon icon="user" pack="fas" type="is-info"></b-icon>
                <b>{{ i18n.details[iso] }}</b>
              </template>
              <!-- Titular -->
              <div class="columns is-multiline">
                <div class="column is-12">
                  <div class="content">
                    <h3>{{ i18n.owner[iso] }}</h3>
                  </div>
                </div>

                <div :class="`column is-${m.size}`" v-for="m in infoMembers">
                  <b-field
                    label-position="on-border"
                    :label="`${i18n[m.fieldIso][iso]} ${m.required ? '*' : ''}`"
                    v-if="m.isInfo"
                  >
                    <b-input
                      v-model="member[m.key]"
                      type="text"
                      expanded
                      :disabled="true"
                    >
                    </b-input>
                  </b-field>

                  <b-field
                    label-position="on-border"
                    :label="`${i18n[m.fieldIso][iso]} ${m.required ? '*' : ''}`"
                    v-if="m.type === 'text' && m.isInfo === false"
                  >
                    <b-input
                      v-model="member[m.key]"
                      type="text"
                      icon-right="times"
                      icon-right-clickable
                      expanded
                      :disabled="!isEdit"
                    >
                    </b-input>
                  </b-field>

                  <b-field
                    label-position="on-border"
                    :label="`${i18n[m.fieldIso][iso]} ${m.required ? '*' : ''}`"
                    v-if="m.type === 'date' && m.isInfo === false"
                  >
                    <b-datepicker
                      v-model="member[m.key]"
                      :locale="iso || 'en'"
                      :disabled="!isEdit"
                    >
                    </b-datepicker>
                  </b-field>

                  <b-field
                    label-position="on-border"
                    :label="`${i18n[m.fieldIso][iso]} ${m.required ? '*' : ''}`"
                    v-if="m.type === 'select' && m.isInfo === false"
                  >
                    <b-select
                      v-model="member[m.key]"
                      expanded
                      :disabled="!isEdit"
                    >
                      <option
                        v-if="!m.optionsIsCatalogue"
                        v-for="cc in m.options"
                        :value="cc[m.valueOption]"
                      >
                        {{ m.showOption(cc) }}
                      </option>
                      <option
                        v-if="m.optionsIsCatalogue"
                        v-for="cc in m.withFilters
                          ? m.optionsFilters(catalogues[m.options])
                          : catalogues[m.options]"
                        :value="cc[m.valueOption]"
                      >
                        {{ m.showOption(cc) }}
                      </option>
                    </b-select>
                  </b-field>
                </div>
              </div>
              <div class="columns is-multiline">
                <div
                  :class="`column is-${
                    (isEdit && !contract.FechaActivacion) ||
                    (isEdit && contract.FechaActivacion) ||
                    (!isEdit &&
                      !contract.FechaActivacion &&
                      (permissions.includes('change-status-contract') ||
                        role === 'admin'))
                      ? 8
                      : 10
                  }`"
                ></div>

                <div
                  class="column is-2"
                  v-if="
                    !contract.FechaActivacion &&
                    !isEdit &&
                    contract.FechaProcesable &&
                    (permissions.includes('change-status-contract') ||
                      role === 'admin')
                  "
                >
                  <b-button
                    :icon-left="
                      contract.EstadoMembresia ? 'check-circle' : 'check'
                    "
                    icon-pack="fas"
                    :type="contract.EstadoMembresia ? 'is-light' : 'is-primary'"
                    expanded
                    @click="editStatusMembership"
                    :loading="loadingButtonEditStatusMembership"
                    >{{ i18n.activate[iso] }}</b-button
                  >
                </div>

                <div class="column is-2" v-if="isEdit">
                  <b-button
                    icon-left="times"
                    icon-pack="fas"
                    type="is-danger"
                    expanded
                    @click="cancelEdit"
                    >{{ i18n.cancel[iso] }}</b-button
                  >
                </div>
                <div class="column is-2">
                  <b-button
                    icon-left="save"
                    icon-pack="fas"
                    type="is-success"
                    expanded
                    @click="editProfile"
                    v-if="isEdit"
                    >{{ i18n.save[iso] }}</b-button
                  >
                  <b-button
                    icon-left="edit"
                    icon-pack="fas"
                    type="is-info"
                    expanded
                    @click="allowEdit"
                    v-else
                    >{{ i18n.edit[iso] }}</b-button
                  >
                </div>
              </div>

              <!-- CoTitular -->
              <div class="columns is-multiline" v-if="coMemberId">
                <div class="column is-12">
                  <div class="content">
                    <h3>{{ i18n.coOwner[iso] }}</h3>
                  </div>
                </div>

                <div
                  :class="`column is-${m.size}`"
                  v-for="m in infoCoMembers()"
                >
                  <b-field
                    label-position="on-border"
                    :label="`${i18n[m.fieldIso][iso]} ${m.required ? '*' : ''}`"
                    v-if="m.isInfo"
                  >
                    <b-input
                      v-model="coOwner[m.key]"
                      type="text"
                      expanded
                      :disabled="true"
                    >
                    </b-input>
                  </b-field>

                  <b-field
                    label-position="on-border"
                    :label="`${i18n[m.fieldIso][iso]} ${m.required ? '*' : ''}`"
                    v-if="m.type === 'text' && m.isInfo === false"
                  >
                    <b-input
                      v-model="coOwner[m.key]"
                      type="text"
                      icon-right="times"
                      icon-right-clickable
                      expanded
                      :disabled="!isCoEdit"
                    >
                    </b-input>
                  </b-field>

                  <b-field
                    label-position="on-border"
                    :label="`${i18n[m.fieldIso][iso]} ${m.required ? '*' : ''}`"
                    v-if="m.type === 'date' && m.isInfo === false"
                  >
                    <b-datepicker
                      v-model="coOwner[m.key]"
                      :locale="iso || 'en'"
                      :disabled="!isCoEdit"
                    >
                    </b-datepicker>
                  </b-field>

                  <!-- <b-field
                    label-position="on-border"
                    :label="`${i18n[m.fieldIso][iso]} ${m.required ? '*' : ''}`"
                    v-if="m.type === 'select' && m.isInfo === false"
                  >
                    <b-select
                      v-model="coOwner[m.key]"
                      expanded
                      :disabled="!isCoEdit"
                    >
                      <option
                        v-if="!m.optionsIsCatalogue"
                        v-for="cc in m.options"
                        :value="cc[m.valueOption]"
                      >
                        {{ m.showOption(cc) }}
                      </option>
                      <option
                        v-if="m.optionsIsCatalogue"
                        v-for="cc in catalogues[m.options]"
                        :value="cc[m.valueOption]"
                      >
                        {{ m.showOption(cc) }}
                      </option>
                    </b-select>
                  </b-field> -->
                  <b-field
                    label-position="on-border"
                    :label="`${i18n[m.fieldIso][iso]} ${m.required ? '*' : ''}`"
                    v-if="m.type === 'select' && m.isInfo === false"
                  >
                    <b-select
                      v-model="coOwner[m.key]"
                      expanded
                      :disabled="!isCoEdit"
                    >
                      <option
                        v-if="!m.optionsIsCatalogue"
                        v-for="cc in m.options"
                        :value="cc[m.valueOption]"
                      >
                        {{ m.showOption(cc) }}
                      </option>
                      <option
                        v-if="m.optionsIsCatalogue"
                        v-for="cc in m.withFilters
                          ? m.optionsFilters(catalogues[m.options])
                          : catalogues[m.options]"
                        :value="cc[m.valueOption]"
                      >
                        {{ m.showOption(cc) }}
                      </option>
                    </b-select>
                  </b-field>
                </div>
              </div>
              <div class="columns is-multiline" v-if="coMemberId">
                <div :class="`column is-${isCoEdit ? 8 : 10}`"></div>
                <div class="column is-2" v-if="isCoEdit">
                  <b-button
                    icon-left="times"
                    icon-pack="fas"
                    type="is-danger"
                    expanded
                    @click="cancelCoEdit"
                    >{{ i18n.cancel[iso] }}</b-button
                  >
                </div>
                <div class="column is-2">
                  <b-button
                    icon-left="save"
                    icon-pack="fas"
                    type="is-success"
                    expanded
                    @click="editCoProfile"
                    v-if="isCoEdit"
                    >{{ i18n.save[iso] }}</b-button
                  >
                  <b-button
                    icon-left="edit"
                    icon-pack="fas"
                    type="is-info"
                    expanded
                    @click="allowCoEdit"
                    v-else
                    >{{ i18n.edit[iso] }}</b-button
                  >
                </div>
              </div>

              <!-- FF -->
              <hr />
              <div class="columns is-multiline">
                <div class="column is-3">
                  <div class="content">
                    <h3>{{ i18n.fraf[iso] }}</h3>
                  </div>
                </div>
                <div class="column is-1" v-if="beneficiaries.length < 5">
                  <b-button
                    icon-left="user-plus"
                    icon-pack="fas"
                    type="is-info"
                    @click="
                      beneficiaries = [
                        ...beneficiaries,
                        {
                          Nombre: null,
                          ApellidoPaterno: null,
                          Parentesco: null,
                          edit: true,
                        },
                      ]
                    "
                    expanded
                  ></b-button>
                </div>
              </div>
              <div
                class="columns is-multiline"
                v-if="beneficiaries && beneficiaries.length > 0"
                v-for="(ff, ffidx) in beneficiaries"
              >
                <div :class="`column is-3`">
                  <b-field
                    label-position="on-border"
                    :label="`${i18n.name[iso]}`"
                  >
                    <b-input
                      v-model="beneficiaries[ffidx].Nombre"
                      type="text"
                      icon-right="times"
                      icon-right-clickable
                      expanded
                      :disabled="!ff.edit"
                    >
                    </b-input>
                  </b-field>
                </div>
                <div :class="`column is-3`">
                  <b-field
                    label-position="on-border"
                    :label="`${i18n.lastname[iso]}`"
                  >
                    <b-input
                      v-model="beneficiaries[ffidx].ApellidoPaterno"
                      type="text"
                      icon-right="times"
                      icon-right-clickable
                      expanded
                      :disabled="!ff.edit"
                    >
                    </b-input>
                  </b-field>
                </div>
                <div :class="`column is-3`">
                  <b-field
                    label-position="on-border"
                    :label="`${i18n.relationship[iso]}`"
                  >
                    <b-select
                      v-model="beneficiaries[ffidx].Parentesco"
                      expanded
                      :disabled="!ff.edit"
                    >
                      <option
                        v-for="cc in catalogues.CatParentescos"
                        :value="cc.Id"
                      >
                        {{ iso === "es" ? cc.Nombre : cc.NombreEn }}
                      </option>
                    </b-select>
                  </b-field>
                </div>
                <div :class="`column is-2`">
                  <b-button
                    icon-left="user-edit"
                    icon-pack="fas"
                    :type="ff.edit ? 'is-success' : 'is-info'"
                    expanded
                    :loading="ff.loading"
                    @click="!ff._id ? createFF(ffidx) : editFF(ff._id, ffidx)"
                    >{{ ff.edit ? i18n.save[iso] : "" }}</b-button
                  >
                </div>
                <div :class="`column is-1`" v-if="ff.edit && ff._id">
                  <b-button
                    icon-left="times"
                    icon-pack="fas"
                    type="is-danger"
                    expanded
                    @click="cancelEditFF(ffidx)"
                  ></b-button>
                </div>
              </div>
            </b-tab-item>
            <b-tab-item>
              <template #header>
                <b-icon icon="file-contract" pack="fas" type="is-info"></b-icon>
                <b>{{ i18n.contract[iso] }}</b>
              </template>

              <!-- Activar membresia -->
              <div class="columns">
                <!-- <div class="column is-3">
                  <b-button
                    icon-left="lightbulb"
                    icon-pack="fas"
                    outlined
                    type="is-success is-light"
                    expanded
                    @click="editStatusMembership"
                    :loading="loadingButtonEditStatusMembership"
                    >{{ i18n.activateMembership[iso] }}</b-button
                  >
                </div> -->
                <!-- <div class="column is-3">
                  <b-button
                    icon-left="lightbulb"
                    icon-pack="fas"
                    outlined
                    :type="`${
                      contract.EstadoContrato === 1
                        ? 'is-success is-light'
                        : contract.EstadoContrato === 2
                        ? 'is-danger is-light'
                        : 'is-info is-light'
                    }`"
                    expanded
                    :loading="loadingButtonEditStatusContract"
                    @click="editStatusContract"
                  >
                    <b-tooltip
                      :label="`${
                        contract.EstadoContrato === 1
                          ? i18n.procesable[iso]
                          : contract.EstadoContrato === 2
                          ? i18n.cancelled[iso]
                          : i18n.pending[iso]
                      }`"
                      :type="'is-dark'"
                    >
                      {{
                        contract.EstadoContrato === 1
                          ? i18n.procesable[iso]
                          : contract.EstadoContrato === 2
                          ? i18n.cancelled[iso]
                          : i18n.pending[iso]
                      }}
                    </b-tooltip>
                  </b-button>
                </div> -->
                <!-- <div class="column is-3">
                  <b-button
                    icon-left="paper-plane"
                    icon-pack="fas"
                    type="is-info"
                    :disabled="
                      contract.EstadoContrato === 3 || !contract.EstadoMembresia
                    "
                    expanded
                    :loading="loadingButtonSendEmailMembershipStatus"
                    @click="sendEmailStatusMembership"
                    >{{ i18n.sendEmailMembershipStatus[iso] }}</b-button
                  >
                </div> -->
              </div>
              <div class="columns is-multiline">
                <div class="column is-12">
                  <div class="content">
                    <h3>{{ i18n.contract[iso] }}</h3>
                  </div>
                </div>
                <div class="column is-2" v-for="item in infoContract">
                  <table
                    v-if="
                      (!item.isCustom && !item.show) ||
                      (!item.isCustom && item.show && item.show())
                    "
                  >
                    <thead>
                      <tr>
                        <th>{{ i18n[item.fieldIso][iso] }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          {{
                            item.isParse
                              ? item.parse(contract[item.key], contract)
                              : contract[item.key]
                          }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <b-field
                    v-if="
                      item.isCustom &&
                      item.key === 'EstadoContrato' &&
                      (permissions.includes('change-status-contract') ||
                        role === 'admin')
                    "
                    label-position="on-border"
                    :label="i18n[item.fieldIso][iso]"
                  >
                    <b-select
                      v-model="contract[item.key]"
                      @input="editStatusContractConfirm"
                      expanded
                    >
                      <option :value="1">
                        {{ i18n.pending[iso] }}
                      </option>
                      <option :value="2">
                        {{ i18n.procesable[iso] }}
                      </option>
                      <option
                        :value="3"
                        :disabled="
                          role !== 'admin' &&
                          !permissions.includes(
                            'change-status-contract__cancel'
                          )
                        "
                      >
                        {{ i18n.cancelled[iso] }}
                      </option>
                    </b-select>
                  </b-field>
                  <b-field
                    v-if="item.isCustom && item.key === 'Idioma'"
                    label-position="on-border"
                    :label="i18n[item.fieldIso][iso]"
                  >
                    <b-select
                      v-model="contract[item.key]"
                      @input="beforeEditLenguage"
                      expanded
                    >
                      <option value="EN">
                        {{ i18n.english[iso] }}
                      </option>
                      <option value="SP">
                        {{ i18n.spanish[iso] }}
                      </option>
                    </b-select>
                  </b-field>
                  <!-- <b-field
                    v-if="
                      item.isCustom &&
                      item.key === 'Idioma'
                    "
                    label-position="on-border"
                    :label="i18n[item.fieldIso][iso]"
                  >
                    <b-select
                      v-model="contract[item.key]"
                      @input="editStatusContractConfirm"
                      expanded
                    >
                      <option value="ES">
                        {{ i18n.spanish[iso] }}
                      </option>
                      <option value="EN">
                        {{ i18n.english[iso] }}
                      </option>
                    </b-select>
                  </b-field> -->
                </div>
              </div>

              <!-- TravelVoucher -->
              <hr v-if="TravelVoucher.length > 0" />
              <div class="columns is-multiline" v-if="TravelVoucher.length > 0">
                <div class="column is-12">
                  <div class="content">
                    <h3>{{ i18n.incentives[iso] }}</h3>
                  </div>
                </div>
              </div>
              <div class="columns is-multiline" v-for="tv in TravelVoucher">
                <div class="column is-2">
                  <b-field
                    label-position="on-border"
                    :label="`${i18n.quantity[iso]}`"
                  >
                    <b-input
                      v-model="tv.Cantidad"
                      type="text"
                      expanded
                      :disabled="true"
                    >
                    </b-input>
                  </b-field>
                </div>
                <div class="column is-2">
                  <b-field
                    label-position="on-border"
                    :label="`${i18n.amount[iso]}`"
                  >
                    <b-input
                      v-model="tv.Monto"
                      type="text"
                      expanded
                      :disabled="true"
                    >
                    </b-input>
                  </b-field>
                </div>
              </div>

              <!-- Detalles del contrato -->
              <div v-if="changeDetailsContract">
                <div class="columns" v-if="detailsContract && club.ModuloPagos">
                  <div class="column is-12">
                    <hr />
                    <div class="content">
                      <h3>{{ i18n.contract[iso] }}</h3>
                    </div>
                  </div>
                </div>

                <div
                  class="column is-3"
                  v-if="detailsContract && club.ModuloPagos"
                >
                  <b-field
                    :label="`${i18n.years[iso]}: ${
                      contract.Years > 80 ? i18n.lifetime[iso] : contract.Years
                    }`"
                  >
                    <b-slider
                      rounded
                      v-model="contract.Years"
                      :min="1"
                      :max="81"
                      :tooltip="contract.Years <= 80"
                      :type="contract.Years > 80 ? 'is-success' : 'is-info'"
                      :tooltip-type="
                        contract.Years > 80 ? 'is-success' : 'is-info'
                      "
                    >
                      <b-slider-tick :value="1">1</b-slider-tick>
                      <b-slider-tick :value="10">10</b-slider-tick>
                      <b-slider-tick :value="20">20</b-slider-tick>
                      <b-slider-tick :value="30">30</b-slider-tick>
                      <b-slider-tick :value="40">40</b-slider-tick>
                      <b-slider-tick :value="50">50</b-slider-tick>
                      <b-slider-tick :value="60">60</b-slider-tick>
                      <b-slider-tick :value="70">70</b-slider-tick>
                      <b-slider-tick :value="80">80</b-slider-tick>
                    </b-slider>
                  </b-field>
                </div>

                <div
                  class="column is-12"
                  v-if="detailsContract && club.ModuloPagos"
                >
                  <hr />
                </div>
                <div v-for="item in editDetailsContract">
                  <div v-if="!item.isArray">
                    <div class="columns is-multiline">
                      <div class="column is-12">
                        <div class="content" v-if="!item.titleHide">
                          <h3>{{ i18n[item.titleIso][iso] }}</h3>
                          <small v-if="item.subtitleIso">{{
                            i18n[item.subtitleIso][iso]
                          }}</small>
                        </div>
                      </div>
                    </div>

                    <div class="columns is-multiline">
                      <div
                        :class="`column is-${m.size}`"
                        v-for="(m, idx) in item.list"
                        :key="idx"
                      >
                        <b-field
                          label-position="on-border"
                          :label="`${i18n[m.fieldIso][iso]} ${
                            m.required ? '*' : ''
                          }`"
                          v-if="m.isInfo"
                        >
                          <b-input
                            v-model="dataEdit[item.ref][m.key]"
                            type="text"
                            expanded
                            :disabled="true"
                          >
                          </b-input>
                        </b-field>

                        <b-field
                          :label="`${i18n[m.fieldIso][iso]} ${
                            m.required ? '*' : ''
                          }`"
                          v-if="
                            (m.type === 'text' ||
                              m.type === 'email' ||
                              m.type === 'number' ||
                              m.type === 'text-custom') &&
                            m.isInfo === false
                          "
                          :type="
                            m.required &&
                            (!dataEdit[item.ref][m.key] ||
                              dataEdit[item.ref][m.key] === '')
                              ? 'is-danger'
                              : ''
                          "
                        >
                          <b-input
                            v-if="m.type === 'number'"
                            v-model="dataEdit[item.ref][m.key]"
                            type="number"
                            icon-right="times"
                            icon-pack="fas"
                            icon-right-clickable
                            @icon-right-click="dataEdit[item.ref][m.key] = null"
                            expanded
                            :icon="m.icon"
                            :min="m.min"
                            :maxlength="m.max || 30"
                            :has-counter="m.max && true"
                          >
                          </b-input>

                          <div
                            v-if="m.type === 'text-custom'"
                            class="control has-icons-left has-icons-right is-expanded is-clearfix"
                          >
                            <input
                              v-model="dataEdit[item.ref][m.key]"
                              type="text"
                              :maxlength="m.max || 30"
                              class="input"
                              @keyup="m.fKeyUp"
                              @input="calcularPagos(null, m.key)"
                            />
                            <span class="icon is-left" v-if="m.icon"
                              ><i :class="`fas fa-${m.icon}`"></i
                            ></span>
                            <span
                              class="icon is-right is-clickable"
                              @click="m.fClear"
                              ><i class="fas fa-times"></i
                            ></span>
                          </div>

                          <b-input
                            v-if="m.rgx && m.type === 'text'"
                            v-model="dataEdit[item.ref][m.key]"
                            type="text"
                            icon-right="times"
                            icon-pack="fas"
                            icon-right-clickable
                            @icon-right-click="dataEdit[item.ref][m.key] = null"
                            expanded
                            :pattern="m.rgx"
                            :validation-message="i18n[m.rgxIso][iso]"
                            :maxlength="m.max || 30"
                            :has-counter="m.max && true"
                          >
                          </b-input>
                          <b-input
                            v-if="!m.rgx && m.type === 'text'"
                            v-model="dataEdit[item.ref][m.key]"
                            type="text"
                            icon-right="times"
                            icon-pack="fas"
                            icon-right-clickable
                            @icon-right-click="dataEdit[item.ref][m.key] = null"
                            expanded
                            :maxlength="m.max || 30"
                            :has-counter="m.max && true"
                          >
                          </b-input>
                        </b-field>

                        <b-field
                          :label="`${i18n[m.fieldIso][iso]} ${
                            m.required ? '*' : ''
                          }`"
                          v-if="m.type === 'date' && m.isInfo === false"
                          :type="
                            m.required && !dataEdit[item.ref][m.key]
                              ? 'is-danger'
                              : ''
                          "
                        >
                          <b-datepicker
                            icon="calendar"
                            icon-pack="fas"
                            v-model="dataEdit[item.ref][m.key]"
                            :locale="iso"
                            :date-formatter="
                              (date) => moment(date).format('LL')
                            "
                            :years-range="[-100, 100]"
                            :max-date="m.maxDate"
                            :min-date="minDate"
                          >
                          </b-datepicker>
                        </b-field>
                      </div>
                    </div>
                    <div class="columns is-multiline">
                      <div class="column is-12">
                        <hr />
                      </div>
                    </div>
                  </div>
                  <div v-if="item.isArray">
                    <div class="columns is-multiline">
                      <div :class="`column is-${item.sizeTitle || 3}`">
                        <div class="content">
                          <h3>{{ i18n[item.titleIso][iso] }}</h3>
                        </div>
                      </div>
                      <div class="column is-2" v-if="item.addMore">
                        <b-button
                          icon-left="plus"
                          icon-pack="fas"
                          type="is-info is-light"
                          expanded
                          size="is-small"
                          @click="item.addMore"
                          >{{ i18n.add[iso] }}</b-button
                        >
                      </div>
                    </div>
                    <div class="columns">
                      <div class="column is-12">
                        <small v-if="item.subtitleIso">{{
                          i18n[item.subtitleIso][iso]
                        }}</small>
                      </div>
                    </div>

                    <div
                      class="columns is-multiline"
                      v-for="(r, ridx) in dataEdit[item.ref]"
                      :key="ridx"
                    >
                      <div
                        :class="`column is-${m.size}`"
                        v-for="(m, idx) in item.list"
                        :key="idx"
                      >
                        <b-field
                          :label="`${i18n[m.fieldIso][iso]} ${
                            m.required ? '*' : ''
                          }`"
                          v-if="
                            (m.type === 'text' ||
                              m.type === 'number' ||
                              m.type === 'text-custom') &&
                            m.isInfo === false
                          "
                        >
                          <b-input
                            v-if="m.rgx && m.type === 'text'"
                            v-model="r[m.key]"
                            type="text"
                            icon-right="times"
                            icon-pack="fas"
                            icon-right-clickable
                            @icon-right-click="r[m.key] = null"
                            expanded
                            :pattern="m.rgx"
                            :validation-message="i18n[m.rgxIso][iso]"
                            :maxlength="m.max || 30"
                            :has-counter="m.max && true"
                          >
                          </b-input>

                          <div
                            v-if="m.type === 'text-custom'"
                            class="control has-icons-left has-icons-right is-expanded is-clearfix"
                          >
                            <input
                              v-model="r[m.key]"
                              type="text"
                              :maxlength="m.max || 30"
                              class="input"
                              @keyup="m.fKeyUp(r[m.key], ridx)"
                            />
                            <span class="icon is-left" v-if="m.icon"
                              ><i :class="`fas fa-${m.icon}`"></i
                            ></span>
                            <span
                              class="icon is-right is-clickable"
                              @click="m.fClear(ridx)"
                              ><i class="fas fa-times"></i
                            ></span>
                          </div>

                          <b-input
                            v-if="!m.rgx && m.type === 'text'"
                            v-model="r[m.key]"
                            type="text"
                            icon-right="times"
                            icon-pack="fas"
                            icon-right-clickable
                            @icon-right-click="r[m.key] = null"
                            expanded
                          >
                          </b-input>
                          <b-input
                            v-if="m.type === 'number'"
                            v-model="r[m.key]"
                            type="number"
                            icon-right="times"
                            icon-pack="fas"
                            icon-right-clickable
                            @icon-right-click="r[m.key] = null"
                            expanded
                            :icon="m.icon"
                            :min="m.min || 0"
                            :maxlength="m.max || 30"
                            :has-counter="m.max && true"
                          >
                          </b-input>
                        </b-field>

                        <b-field
                          :label="`${i18n[m.fieldIso][iso]} ${
                            m.required ? '*' : ''
                          }`"
                          v-if="m.type === 'date' && m.isInfo === false"
                          :type="m.required && !r[m.key] ? 'is-danger' : ''"
                        >
                          <b-datepicker
                            icon="calendar"
                            icon-pack="fas"
                            v-model="r[m.key]"
                            :locale="iso"
                            :date-formatter="
                              (date) => moment(date).format('LL')
                            "
                            :years-range="[-100, 100]"
                            :max-date="maxDateOther"
                            :min-date="minDate"
                          >
                          </b-datepicker>
                        </b-field>

                        <b-field
                          :label="`${i18n[m.fieldIso][iso]} ${
                            m.required ? '*' : ''
                          }`"
                          v-if="m.type === 'select' && m.isInfo === false"
                        >
                          <b-select v-model="r[m.key]" expanded>
                            <option :value="null">
                              {{ i18n.selectAnOption[iso] }}
                            </option>
                            <option
                              v-if="!m.optionsIsCatalogue"
                              v-for="cc in m.options"
                              :value="cc[m.valueOption]"
                            >
                              {{ m.showOption(cc) }}
                            </option>
                            <option
                              v-if="m.optionsIsCatalogue"
                              v-for="cc in m.withFilters
                                ? m.optionsFilters(catalogues[m.options])
                                : catalogues[m.options]"
                              :value="cc[m.valueOption]"
                            >
                              {{ m.showOption(cc) }}
                            </option>
                          </b-select>
                        </b-field>
                      </div>
                    </div>

                    <div class="columns is-multiline">
                      <div class="column is-12">
                        <hr />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="columns is-multiline">
                  <div class="column is-9"></div>
                  <div class="column is-3">
                    <b-button
                      icon-left="retweet"
                      icon-pack="fas"
                      type="is-success"
                      expanded
                      @click="updateDetailsContract"
                      >{{ i18n.save[iso] }}</b-button
                    >
                  </div>
                </div>
              </div>
              <div v-else>
                <div
                  class="column is-12"
                  v-if="detailsContract && club.ModuloPagos"
                >
                  <hr />
                </div>
                <div
                  class="columns is-multiline"
                  v-if="detailsContract && club.ModuloPagos"
                >
                  <div class="column is-12">
                    <div class="content">
                      <h3>{{ i18n.detailsContract[iso] }}</h3>
                    </div>
                  </div>
                  <div class="column is-2" v-for="item in infoDetailsContract">
                    <table v-if="!item.isCustom">
                      <thead>
                        <tr>
                          <th>{{ i18n[item.fieldIso][iso] }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            {{
                              item.isParse
                                ? item.parse(
                                    detailsContract[item.key],
                                    detailsContract
                                  )
                                : detailsContract[item.key]
                            }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div
                  class="columns is-multiline"
                  v-if="
                    detailsContract &&
                    club.ModuloPagos &&
                    club.ModuloUpDowngrade
                  "
                >
                  <div class="column is-9"></div>
                  <div class="column is-3">
                    <b-button
                      icon-left="arrow-up"
                      icon-pack="fas"
                      type="is-success is-light"
                      expanded
                      outlined
                      @click="upDownContract('up')"
                      >{{ i18n.upgrade[iso] }}</b-button
                    >
                    <br />
                    <b-button
                      icon-left="arrow-down"
                      icon-pack="fas"
                      type="is-info is-light"
                      expanded
                      outlined
                      @click="upDownContract('down')"
                      >{{ i18n.downgrade[iso] }}</b-button
                    >
                  </div>
                </div>
              </div>
              <hr />
              <div
                v-if="
                  contract && contract.Id && club && club.Plantillas.length > 0
                "
              >
                <DocContractMemberCap
                  :contract="contract"
                  :templateDocuments="club.Plantillas"
                  :club="club"
                  @refreshProfile="getProfile"
                />
              </div>
            </b-tab-item>
            <b-tab-item :visible="false">
              <template #header>
                <b-icon icon="file-pdf" pack="fas" type="is-info"></b-icon>
                <b>{{ i18n.documents[iso] }}</b>
              </template>

              <div class="columns is-multiline">
                <div class="column is-12">
                  <div class="content">
                    <h3>{{ i18n.generateDocuments[iso] }}</h3>
                  </div>
                </div>
              </div>
              <div class="columns is-multiline">
                <div class="column is-3" v-for="templ in clubTemplates">
                  <b-button
                    icon-left="file-pdf"
                    icon-pack="fas"
                    type="is-info"
                    expanded
                    :loading="templLoading === templ"
                    @click="downloadPdfOwners(templ)"
                    >{{ checkI18n(templ) }}</b-button
                  >
                </div>
                <!-- <div class="column is-3">
                  <b-button
                    icon-left="file-pdf"
                    icon-pack="fas"
                    type="is-info is-light"
                    expanded
                    :loading="loadingButtonDownloadPdfContract__b"
                    :disabled="beneficiaries.length > 0 ? false : true"
                    @click="downloadPdfOwners('friendsAndFamily')"
                    >Friends and Family</b-button
                  >
                </div>
                <div class="column is-3">
                  <b-button
                    icon-left="file-pdf"
                    icon-pack="fas"
                    type="is-primary is-light"
                    expanded
                    :loading="loadingButtonDownloadPdfContract__t"
                    :disabled="
                      detailsContract &&
                      detailsContract.TravelVoucher.length > 0
                        ? false
                        : true
                    "
                    @click="downloadPdfOwners('travelVoucher')"
                    >Travel Voucher</b-button
                  >
                </div> -->
              </div>
              <hr />

              <div class="columns is-multiline">
                <!-- <div class="column is-12">
                  <div class="content">
                    <h3>{{ i18n.uploadDocuments[iso] }}</h3>
                  </div>
                </div> -->
              </div>
              <div class="columns is-multiline">
                <div class="column is-6">
                  <div class="columns is-multiline">
                    <div class="column is-12">
                      <div class="content">
                        <h3>{{ i18n.uploadDocuments[iso] }}</h3>
                      </div>
                    </div>

                    <div class="column is-6">
                      <b-field class="file">
                        <b-upload v-model="file" expanded>
                          <a class="button is-success is-light is-fullwidth">
                            <b-icon icon="upload" icon-pack="fas"></b-icon>
                            <span>{{
                              (file && file.name) || i18n.clickToUpload[iso]
                            }}</span>
                          </a>
                        </b-upload>
                      </b-field>
                      <b-button
                        icon-left="upload"
                        icon-pack="fas"
                        type="is-success"
                        :disabled="!file"
                        :loading="loadingButtonUploadPdfContract"
                        expanded
                        @click="uploadFile"
                        >{{ i18n.upload[iso] }}</b-button
                      >
                    </div>
                  </div>
                </div>
                <div class="column is-6">
                  <div class="columns is-multiline">
                    <div class="column is-12">
                      <div class="content">
                        <h3>{{ i18n.listDocuments[iso] }}</h3>
                      </div>
                    </div>
                    <b-field grouped group-multiline>
                      <div class="control" v-for="doc in contract.Documentos">
                        <b-tag
                          size="is-medium"
                          icon="file-pdf"
                          icon-pack="fas"
                          type="is-info is-light"
                          attached
                          aria-close-label="Close tag"
                          closable
                          close-type="is-info is-light"
                          close-icon-type="is-dark"
                          close-icon="external-link-alt"
                          close-icon-pack="fas"
                          @close="showDoc(doc)"
                        >
                          {{ doc }}
                        </b-tag>
                      </div>
                    </b-field>
                  </div>
                </div>
              </div>
            </b-tab-item>
            <b-tab-item>
              <template #header>
                <b-icon icon="comment" pack="fas" type="is-info"></b-icon>
                <b>{{ i18n.comments[iso] }}</b>
              </template>
              <div class="columns is-multiline"></div>
              <comment-member-cap
                ref="commentmember"
                v-if="contract.Id"
                :ContratoId="contract.Id"
                :topicSelectedDefault="99"
              ></comment-member-cap>
            </b-tab-item>
          </b-tabs>

          <hr />
          <!-- <div class="content article-body"></div> -->
        </div>
      </div>
    </section>
    <b-loading
      :is-full-page="true"
      v-model="loading"
      :can-cancel="false"
    ></b-loading>
  </div>
</template>

<script>
import axios from "axios";
const API_URL = process.env.VUE_APP_API_URL;
import moment from "moment";
import i18n from "../../utils/i18n";
import codCountries from "../../utils/codeCountries";
import CommentMember from "../Membership/CommentMember";
import CommentMemberCap from "../Capturador/CommentMemberCap";
import DocContractMemberCap from "../Capturador/DocContractMemberCap";

const TEMPLATE_MEMBER = {
  Nombre: null,
  ApellidoPaterno: null,
  // ApellidoMaterno: null,
  Email: null,
  FechaNacimiento: null,
  LadaTelefono: null,
  Telefono: null,
  LadaCelular: null,
  Celular: null,
  Pais: null,
  Estado: null,
  Ciudad: null,
  Direccion: null,
  CodigoPostal: null,
  Nacionalidad: null,
  EstadoCivil: null,
  TipoSocio: null,
  FechaRegistro: null,
  Idioma: null,
};

const listCatalogues = [
  "CatEstadosPais",
  "CatEstadoCivil",
  "CatIdiomas",
  "CatTipoSocio",
  "CatPaises",
  "CatPeriodicidad",
  "CatStatusContrato",
  "CatTipoVenta",
  "CatParentescos",
];

export default {
  data() {
    return {
      moment,
      isPaginated: true,
      isPaginationSimple: true,
      isPaginationRounded: false,
      paginationPosition: "bottom",
      defaultSortDirection: "asc",
      sortIcon: "arrow-up",
      sortIconSize: "is-small",
      currentPage: 1,
      perPage: 10,
      hasInput: false,
      paginationOrder: "",
      inputPosition: "",
      inputDebounce: "",
      isBordered: false,
      isStriped: true,
      isNarrowed: true,
      isHoverable: true,
      isFocusable: false,
      hasMobileCards: true,
      memberId: null,
      coMemberId: null,

      //i18n
      i18n,
      iso: "en",

      //Permissions
      permissions: [],
      role: null,

      //
      codCountries: codCountries.map((c) => {
        return {
          ...c,
          dial_code: c.dial_code.substring(1),
        };
      }),
      loading: false,
      data: null,
      member: {
        ...TEMPLATE_MEMBER,
      },
      coOwner: {
        ...TEMPLATE_MEMBER,
      },
      contract: {
        Numero: "",
        FechaRenovacion: "",
        LoginID: "",
        Id: null,
        EstadoMembresia: false,
        EstadoContrato: 1,
        Documentos: [],
        Years: 0,
      },
      // detailsContract: {
      //   TravelVoucher: []
      // },
      detailsContract: null,
      TravelVoucher: [],
      beneficiaries: [],
      club: {
        Nombre: null,
        ModuloPagos: false,
        Id: null,
        Plantillas: [],
        ModuloUpDowngrade: false,
      },
      renovations: [],
      statusAccounts: [],
      activeTab: 0,
      isEdit: false,
      isCoEdit: false,
      listCatalogues,
      catalogues: {},
      selectedRow: null,
      loadingButtonEditStatusMembership: false,
      loadingButtonEditStatusContract: false,
      loadingButtonSendEmailMembershipStatus: false,
      loadingButtonDownloadPdfContract: false,
      loadingButtonUploadPdfContract: false,
      loadingButtonDownloadPdfContract__b: false,
      loadingButtonDownloadPdfContract__t: false,
      file: null,
      statusContractOld: 1,
      clubTemplates: [],
      templLoading: null,
      changeDetailsContract: false,
      dataEdit: {
        detailsContract: {},
        conditionalDownPayment: [
          // {
          //   FechaPago: null,
          //   CargoPago: null,
          //   TipoPago: "CDP",
          // },
        ],
        additionalPayments: [
          // {
          //   FechaPago: null,
          //   CargoPago: null,
          //   TipoPago: "ADP",
          // },
        ],
      },
      minDate: new Date(
        moment().set({
          year: 1930,
          month: 0,
          date: 1,
          hour: 0,
          second: 0,
          millisecond: 0,
        })
      ),
      maxDateToday: new Date(),
      maxDateBirthdate: new Date(moment().subtract(18, "years")),
      maxDateOther: new Date(moment().add(10, "years")),
      typeUpDown: null,
      newYears: null,

      /**
       * Info
       */
      infoMembers: [
        {
          key: "Nombre",
          fieldIso: "name",
          type: "text",
          size: 3,
          isInfo: false,
          required: true,
        },
        {
          key: "ApellidoPaterno",
          fieldIso: "lastname_ext",
          type: "text",
          size: 3,
          isInfo: false,
          required: true,
        },
        // {
        //   key: "ApellidoMaterno",
        //   fieldIso: "mothersLastname",
        //   type: "text",
        //   size: 3,
        //   isInfo: false,
        // },
        {
          key: "Email",
          fieldIso: "email",
          type: "text",
          size: 3,
          isInfo: false,
          required: true,
        },
        {
          key: "FechaNacimiento",
          fieldIso: "birthdate",
          type: "date",
          size: 3,
          isInfo: false,
          required: false,
        },
        {
          key: "LadaTelefono",
          fieldIso: "codCellphone",
          type: "select",
          size: 3,
          isInfo: false,
          options: "CatPaises",
          optionsIsCatalogue: true,
          optionsFilters: (data) => {
            if (!data || data.length === 0) return [];
            let tmp = [...data];
            return tmp
              .reduce((acum, value) => {
                const exists = acum.find((a) => a.Lada === value.Lada);
                if (!exists) acum.push(value);
                return acum;
              }, [])
              .sort((a, b) => (a.Lada > b.Lada ? 1 : b.Lada > a.Lada ? -1 : 0));
          },
          withFilters: true,
          valueOption: "Lada",
          showOption: (tmp) => {
            return `+${tmp.Lada}`;
          },
          required: true,
        },
        {
          key: "Telefono",
          fieldIso: "cellphone",
          type: "text",
          size: 3,
          isInfo: false,
          required: true,
        },
        {
          key: "LadaCelular",
          fieldIso: "codPhone",
          type: "select",
          size: 3,
          isInfo: false,
          options: "CatPaises",
          optionsIsCatalogue: true,
          optionsFilters: (data) => {
            if (!data || data.length === 0) return [];
            let tmp = [...data];
            return tmp
              .reduce((acum, value) => {
                const exists = acum.find((a) => a.Lada === value.Lada);
                if (!exists) acum.push(value);
                return acum;
              }, [])
              .sort((a, b) => (a.Lada > b.Lada ? 1 : b.Lada > a.Lada ? -1 : 0));
          },
          withFilters: true,
          valueOption: "Lada",
          showOption: (tmp) => {
            return `+${tmp.Lada}`;
          },
        },
        {
          key: "Celular",
          fieldIso: "phone",
          type: "text",
          size: 3,
          isInfo: false,
        },
        {
          key: "Pais",
          fieldIso: "country",
          type: "select",
          size: 3,
          isInfo: false,
          options: "CatPaises",
          optionsIsCatalogue: true,
          valueOption: "Id",
          showOption: (tmp) => {
            return this.iso === "es" ? tmp.Nombre : tmp.NombreEn;
          },
          required: true,
        },
        {
          key: "Estado",
          fieldIso: "state",
          type: "select",
          size: 3,
          isInfo: false,
          options: "CatEstadosPais",
          optionsIsCatalogue: true,
          valueOption: "Id",
          optionsFilters: (data) => {
            if (!data || data.length === 0) return [];
            else return data.filter((d) => d.PaisId === this.member.Pais);
          },
          withFilters: true,
          showOption: (tmp) => {
            return this.iso === "es" ? tmp.Nombre : tmp.NombreEng;
          },
          required: true,
        },
        {
          key: "Ciudad",
          fieldIso: "city",
          type: "text",
          size: 3,
          isInfo: false,
          required: true,
        },
        {
          key: "Direccion",
          fieldIso: "address",
          type: "text",
          size: 3,
          isInfo: false,
          required: true,
        },
        {
          key: "CodigoPostal",
          fieldIso: "zip",
          type: "text",
          size: 3,
          isInfo: false,
          required: true,
        },
        {
          key: "Nacionalidad",
          fieldIso: "nationality",
          type: "select",
          size: 3,
          isInfo: false,
          options: "CatPaises",
          optionsIsCatalogue: true,
          valueOption: "Id",
          showOption: (tmp) => {
            return this.iso === "es" ? tmp.Nacionalidad : tmp.NacionalidadEn;
          },
          required: true,
        },
        {
          key: "EstadoCivil",
          fieldIso: "civilStatus",
          type: "select",
          size: 3,
          isInfo: false,
          options: "CatEstadoCivil",
          optionsIsCatalogue: true,
          valueOption: "Id",
          showOption: (tmp) => {
            return this.iso === "es" ? tmp.Nombre : tmp.NombreEn;
          },
          required: false,
        },
        {
          key: "Idioma",
          fieldIso: "language",
          type: "select",
          size: 3,
          isInfo: false,
          options: "CatIdiomas",
          optionsIsCatalogue: true,
          valueOption: "Id",
          showOption: (tmp) => {
            return this.iso === "es" ? tmp.Nombre : tmp.NombreEn;
          },
          required: true,
        },
        // {
        //   key: "FechaRegistro",
        //   fieldIso: "registrationDate",
        //   type: "text",
        //   size: 3,
        //   isInfo: true,
        // },
      ],
      infoCoMembers: () => [
        // ...this.infoMembers,
        // ...this.infoMembers.filter((t) =>
        //   [
        //     "Nombre",
        //     "ApellidoPaterno",
        //     "Email",
        //     "LadaTelefono",
        //     "Telefono",
        //   ].includes(t.key)
        // ),
        {
          key: "Nombre",
          fieldIso: "name",
          type: "text",
          size: 3,
          isInfo: false,
          required: true,
        },
        {
          key: "ApellidoPaterno",
          fieldIso: "lastname_ext",
          type: "text",
          size: 3,
          isInfo: false,
          required: true,
        },
        // {
        //   key: "ApellidoMaterno",
        //   fieldIso: "mothersLastname",
        //   type: "text",
        //   size: 3,
        //   isInfo: false,
        // },
        {
          key: "Email",
          fieldIso: "email",
          type: "text",
          size: 3,
          isInfo: false,
          required: true,
        },
        {
          key: "FechaNacimiento",
          fieldIso: "birthdate",
          type: "date",
          size: 3,
          isInfo: false,
          required: false,
        },
        {
          key: "LadaTelefono",
          fieldIso: "codCellphone",
          type: "select",
          size: 3,
          isInfo: false,
          options: "CatPaises",
          optionsIsCatalogue: true,
          optionsFilters: (data) => {
            if (!data || data.length === 0) return [];
            let tmp2 = [...data];
            return tmp2
              .reduce((acum, value) => {
                const exists = acum.find((a) => a.Lada === value.Lada);
                if (!exists) acum.push(value);
                return acum;
              }, [])
              .sort((a, b) => (a.Lada > b.Lada ? 1 : b.Lada > a.Lada ? -1 : 0));
          },
          withFilters: true,
          valueOption: "Lada",
          showOption: (tmp) => {
            return `+${tmp.Lada}`;
          },
          required: false,
        },
        {
          key: "Telefono",
          fieldIso: "cellphone",
          type: "text",
          size: 3,
          isInfo: false,
          required: false,
        },
        {
          key: "LadaCelular",
          fieldIso: "codPhone",
          type: "select",
          size: 3,
          isInfo: false,
          options: "CatPaises",
          optionsIsCatalogue: true,
          optionsFilters: (data) => {
            if (!data || data.length === 0) return [];
            let tmp = [...data];
            return tmp
              .reduce((acum, value) => {
                const exists = acum.find((a) => a.Lada === value.Lada);
                if (!exists) acum.push(value);
                return acum;
              }, [])
              .sort((a, b) => (a.Lada > b.Lada ? 1 : b.Lada > a.Lada ? -1 : 0));
          },
          withFilters: true,
          valueOption: "Lada",
          showOption: (tmp) => {
            return `+${tmp.Lada}`;
          },
        },
        {
          key: "Celular",
          fieldIso: "phone",
          type: "text",
          size: 3,
          isInfo: false,
        },
        {
          key: "Pais",
          fieldIso: "country",
          type: "select",
          size: 3,
          isInfo: false,
          options: "CatPaises",
          optionsIsCatalogue: true,
          valueOption: "Id",
          showOption: (tmp) => {
            return this.iso === "es" ? tmp.Nombre : tmp.NombreEn;
          },
          required: false,
        },
        {
          key: "Estado",
          fieldIso: "state",
          type: "select",
          size: 3,
          isInfo: false,
          options: "CatEstadosPais",
          optionsIsCatalogue: true,
          valueOption: "Id",
          optionsFilters: (data) => {
            if (!data || data.length === 0) return [];
            else return data.filter((d) => d.PaisId === this.member.Pais);
          },
          withFilters: true,
          showOption: (tmp) => {
            return this.iso === "es" ? tmp.Nombre : tmp.NombreEng;
          },
          required: false,
        },
        {
          key: "Ciudad",
          fieldIso: "city",
          type: "text",
          size: 3,
          isInfo: false,
          required: false,
        },
        {
          key: "Direccion",
          fieldIso: "address",
          type: "text",
          size: 3,
          isInfo: false,
          required: false,
        },
        {
          key: "CodigoPostal",
          fieldIso: "zip",
          type: "text",
          size: 3,
          isInfo: false,
          required: false,
        },
        {
          key: "Nacionalidad",
          fieldIso: "nationality",
          type: "select",
          size: 3,
          isInfo: false,
          options: "CatPaises",
          optionsIsCatalogue: true,
          valueOption: "Id",
          showOption: (tmp) => {
            return this.iso === "es" ? tmp.Nacionalidad : tmp.NacionalidadEn;
          },
          required: false,
        },
        {
          key: "EstadoCivil",
          fieldIso: "civilStatus",
          type: "select",
          size: 3,
          isInfo: false,
          options: "CatEstadoCivil",
          optionsIsCatalogue: true,
          valueOption: "Id",
          showOption: (tmp) => {
            return this.iso === "es" ? tmp.Nombre : tmp.NombreEn;
          },
          required: false,
        },
        {
          key: "Idioma",
          fieldIso: "language",
          type: "select",
          size: 3,
          isInfo: false,
          options: "CatIdiomas",
          optionsIsCatalogue: true,
          valueOption: "Id",
          showOption: (tmp) => {
            return this.iso === "es" ? tmp.Nombre : tmp.NombreEn;
          },
          required: true,
        },
      ],
      infoContract: [
        {
          key: "Numero",
          fieldIso: "contractNumber",
        },
        {
          key: "LoginID",
          fieldIso: "LoginID",
        },
        {
          key: "Periodicidad",
          fieldIso: "period",
          isParse: true,
          parse: (value) => {
            const cat = this.catalogues["CatPeriodicidad"];
            if (cat) {
              const rgx = new RegExp(value, "ig");
              const tmp = cat.find((c) => rgx.test(c.Id));
              if (tmp) return this.iso === "es" ? tmp.Nombre : tmp.NombreEn;
              else return "";
            } else return "";
          },
        },
        {
          key: "Years",
          fieldIso: "years",
          isParse: true,
          parse: (value) => {
            return value > 80 ? "Lifetime" : value;
          },
        },
        {
          key: "Tarifa",
          fieldIso: "fee",
          isParse: true,
          parse: (value, obj = []) => {
            return `$ ${value} ${obj.Moneda}`;
          },
        },
        {
          key: "FechaCompra",
          fieldIso: "purchaseDate",
          isParse: true,
          parse: (value) => {
            return this.parseDate(value);
          },
        },
        {
          key: "FechaRegistro",
          fieldIso: "registrationDate",
          isParse: true,
          parse: (value) => {
            return this.parseDate(value);
          },
        },
        {
          key: "FechaProcesable",
          fieldIso: "processableDate",
          isParse: true,
          parse: (value) => {
            return this.parseDate(value);
          },
        },
        // {
        //   key: "FechaRenovacion",
        //   fieldIso: "renovationDate",
        //   isParse: true,
        //   parse: (value) => {
        //     return this.parseDate(value);
        //   },
        // },
        {
          key: "FechaCancelado",
          fieldIso: "cancellationDate",
          isParse: true,
          parse: (value) => {
            return this.parseDate(value);
          },
        },
        {
          key: "Idioma",
          fieldIso: "language",
          isParse: true,
          isCustom: true,
          // isParse: true,
          // parse: (value) => {
          //   const cat = this.catalogues["CatIdiomas"];
          //   if (cat) {
          //     const tmp = cat.find(
          //       (c) => c.Id === value.toString().toLowerCase()
          //     );
          //     return this.iso === "es" ? tmp.NombreEs : tmp.NombreEn;
          //   }
          //   return value;
          // },
        },
        // {
        //   key: "FechaRenovacion",
        //   fieldIso: "subscriptionStatus",
        //   isParse: true,
        //   parse: (value) => {
        //     const now = Date.now();
        //     return value && value >= now
        //       ? this.i18n.current[this.iso]
        //       : value && value < now
        //       ? this.i18n.expired[this.iso]
        //       : "";
        //   },
        // },
        // {
        //   key: "EstadoMembresia",
        //   fieldIso: "membershipStatus",
        //   isParse: true,
        //   parse: (value) => {
        //     return value === true
        //       ? this.i18n.active[this.iso]
        //       : value === false
        //       ? this.i18n.inactive[this.iso]
        //       : "";
        //   },
        // },
        {
          key: "EstadoContrato",
          fieldIso: "contractStatus",
          isParse: true,
          isCustom: true,
          parse: (value) => {
            const cat = this.catalogues["CatStatusContrato"];
            if (cat) {
              const tmp = cat.find((c) => value === c.Id);
              if (tmp) return this.iso === "es" ? tmp?.Nombre : tmp?.NombreEn;
              else return "";
            } else return "";
          },
        },
        {
          key: "PrecioContrato",
          fieldIso: "priceContract",
          isParse: true,
          parse: (value, obj = []) => {
            return `$ ${
              (this.detailsContract && this.detailsContract?.PrecioContrato) ||
              ""
            }`;
          },
          show: () => {
            return !this.club?.ModuloPagos;
          },
        },
      ],
      infoDetailsContract: [
        {
          key: "PrecioContrato",
          fieldIso: "priceContract",
          isParse: true,
          parse: (value) => {
            if (!value && value !== 0) return "";
            let parts = value.toString().split(".");
            let v = parts[0].replace(/\D/g, ""),
              dec = parts[1];
            let n = new Intl.NumberFormat("en-EN").format(v);
            n = dec !== undefined ? n + "." + dec : n;
            return `$ ${n}`;
          },
        },
        {
          key: "CostoAdministracion",
          fieldIso: "administrationCost",
          isParse: true,
          parse: (value) => {
            if (!value && value !== 0) return "";
            let parts = value.toString().split(".");
            let v = parts[0].replace(/\D/g, ""),
              dec = parts[1];
            let n = new Intl.NumberFormat("en-EN").format(v);
            n = dec !== undefined ? n + "." + dec : n;
            return `$ ${n}`;
          },
        },
        {
          key: "PagoInicial",
          fieldIso: "initialPayment",
          isParse: true,
          parse: (value) => {
            if (!value && value !== 0) return "";
            let parts = value.toString().split(".");
            let v = parts[0].replace(/\D/g, ""),
              dec = parts[1];
            let n = new Intl.NumberFormat("en-EN").format(v);
            n = dec !== undefined ? n + "." + dec : n;
            return `$ ${n}`;
          },
        },
        {
          key: "PorcentajePagoInicial",
          fieldIso: "downPaymentPercentage",
          isParse: true,
          parse: (value) => {
            if (!value && value !== 0) return "";
            let parts = value.toString().split(".");
            let v = parts[0].replace(/\D/g, ""),
              dec = parts[1];
            let n = new Intl.NumberFormat("en-EN").format(v);
            n = dec !== undefined ? n + "." + dec : n;
            return `${n}%`;
          },
        },
        {
          key: "Saldo",
          fieldIso: "balance",
          isParse: true,
          parse: (value) => {
            if (!value && value !== 0) return "";
            let parts = value.toString().split(".");
            let v = parts[0].replace(/\D/g, ""),
              dec = parts[1];
            let n = new Intl.NumberFormat("en-EN").format(v);
            n = dec !== undefined ? n + "." + dec : n;
            return `$ ${n}`;
          },
        },
        {
          key: "Mensualidades",
          fieldIso: "monthlyPayments",
          type: "number",
          size: 3,
          isInfo: false,
          required: false,
        },
        {
          key: "PagoMensual",
          fieldIso: "monthlyPayment",
          isParse: true,
          parse: (value) => {
            if (!value && value !== 0) return "";
            let parts = value.toString().split(".");
            let v = parts[0].replace(/\D/g, ""),
              dec = parts[1];
            let n = new Intl.NumberFormat("en-EN").format(v);
            n = dec !== undefined ? n + "." + dec : n;
            return `$ ${n}`;
          },
        },
        {
          key: "FechaPrimeraMensualidad",
          fieldIso: "firstMonthlyDate",
          isParse: true,
          parse: (value) => {
            if (!value) return "";
            const date = moment(value).locale(this.iso).format("ll");
            return date;
          },
        },
        {
          key: "TasaInteres",
          fieldIso: "interestRate",
          isParse: true,
          parse: (value) => {
            if (!value && value !== 0) return "";
            let parts = value.toString().split(".");
            let v = parts[0].replace(/\D/g, ""),
              dec = parts[1];
            let n = new Intl.NumberFormat("en-EN").format(v);
            n = dec !== undefined ? n + "." + dec : n;
            return `${n}%`;
          },
        },
      ],
      editDetailsContract: [
        {
          titleIso: "detailsContract",
          ref: "detailsContract",
          list: [
            {
              key: "PrecioContrato",
              fieldIso: "priceContract",
              type: "text-custom",
              size: 3,
              isInfo: false,
              required: false,
              icon: "dollar-sign",
              fClear: () => {
                this.dataEdit.detailsContract.PrecioContrato = null;
                this.$forceUpdate();
              },
              fKeyUp: (e) => {
                let parts = e.target.value.split(".");
                let v = parts[0].replace(/\D/g, ""),
                  dec = parts[1];
                let calc_num = Number(dec !== undefined ? v + "." + dec : v);
                // use this for numeric calculations
                // console.log('number for calculations: ', calc_num);
                let n = new Intl.NumberFormat("en-EN").format(v);
                n = dec !== undefined ? n + "." + dec : n;
                // $(this).val(n);
                this.dataEdit.detailsContract.PrecioContrato = n;
                this.$forceUpdate();
              },
            },
            {
              key: "CostoAdministracion",
              fieldIso: "administrationCost",
              type: "text-custom",
              size: 3,
              isInfo: false,
              required: false,
              icon: "dollar-sign",
              fClear: () => {
                this.dataEdit.detailsContract.CostoAdministracion = null;
                this.$forceUpdate();
              },
              fKeyUp: (e) => {
                let parts = e.target.value.split(".");
                let v = parts[0].replace(/\D/g, ""),
                  dec = parts[1];
                let calc_num = Number(dec !== undefined ? v + "." + dec : v);
                // use this for numeric calculations
                // console.log('number for calculations: ', calc_num);
                let n = new Intl.NumberFormat("en-EN").format(v);
                n = dec !== undefined ? n + "." + dec : n;
                // $(this).val(n);
                this.dataEdit.detailsContract.CostoAdministracion = n;
                this.$forceUpdate();
              },
            },
            {
              key: "PagoInicial",
              fieldIso: "initialPayment",
              type: "text-custom",
              size: 3,
              isInfo: false,
              required: false,
              icon: "dollar-sign",
              fClear: () => {
                this.dataEdit.detailsContract.PagoInicial = null;
                this.$forceUpdate();
              },
              fKeyUp: (e) => {
                let parts = e.target.value.split(".");
                let v = parts[0].replace(/\D/g, ""),
                  dec = parts[1];
                let calc_num = Number(dec !== undefined ? v + "." + dec : v);
                // use this for numeric calculations
                // console.log('number for calculations: ', calc_num);
                let n = new Intl.NumberFormat("en-EN").format(v);
                n = dec !== undefined ? n + "." + dec : n;
                // $(this).val(n);
                this.dataEdit.detailsContract.PagoInicial = n;
                this.$forceUpdate();
              },
            },
            {
              key: "PorcentajePagoInicial",
              fieldIso: "downPaymentPercentage",
              type: "text-custom",
              size: 3,
              isInfo: false,
              required: false,
              icon: "percentage",
              max: 4,
              fClear: () => {
                this.dataEdit.detailsContract.PorcentajePagoInicial = null;
                this.$forceUpdate();
              },
              fKeyUp: (e) => {
                let parts = e.target.value.split(".");
                let v = parts[0].replace(/\D/g, ""),
                  dec = parts[1];
                let calc_num = Number(dec !== undefined ? v + "." + dec : v);
                // use this for numeric calculations
                // console.log('number for calculations: ', calc_num);
                let n = new Intl.NumberFormat("en-EN").format(v);
                n = dec !== undefined ? n + "." + dec : n;
                // $(this).val(n);
                this.dataEdit.detailsContract.PorcentajePagoInicial = n;
                this.$forceUpdate();
              },
            },
            {
              key: "Saldo",
              fieldIso: "balance",
              type: "text-custom",
              size: 3,
              isInfo: false,
              required: false,
              icon: "dollar-sign",
              fClear: () => {
                this.dataEdit.detailsContract.Saldo = null;
                this.$forceUpdate();
              },
              fKeyUp: (e) => {
                let parts = e.target.value.split(".");
                let v = parts[0].replace(/\D/g, ""),
                  dec = parts[1];
                let calc_num = Number(dec !== undefined ? v + "." + dec : v);
                // use this for numeric calculations
                // console.log('number for calculations: ', calc_num);
                let n = new Intl.NumberFormat("en-EN").format(v);
                n = dec !== undefined ? n + "." + dec : n;
                // $(this).val(n);
                this.dataEdit.detailsContract.Saldo = n;
                this.$forceUpdate();
              },
            },
            {
              key: "Mensualidades",
              fieldIso: "monthlyPayments",
              type: "number",
              size: 3,
              isInfo: false,
              required: false,
            },
            {
              key: "PagoMensual",
              fieldIso: "monthlyPayment",
              type: "text-custom",
              size: 3,
              isInfo: false,
              required: false,
              icon: "dollar-sign",
              fClear: () => {
                this.dataEdit.detailsContract.PagoMensual = null;
                this.$forceUpdate();
              },
              fKeyUp: (e) => {
                let parts = e.target.value.split(".");
                let v = parts[0].replace(/\D/g, ""),
                  dec = parts[1];
                let calc_num = Number(dec !== undefined ? v + "." + dec : v);
                // use this for numeric calculations
                // console.log('number for calculations: ', calc_num);
                let n = new Intl.NumberFormat("en-EN").format(v);
                n = dec !== undefined ? n + "." + dec : n;
                // $(this).val(n);
                this.dataEdit.detailsContract.PagoMensual = n;
                this.$forceUpdate();
              },
            },
            {
              key: "FechaPrimeraMensualidad",
              fieldIso: "firstMonthlyDate",
              type: "date",
              size: 3,
              isInfo: false,
              required: false,
              maxDate: new Date(moment().add(10, "years")),
            },
            {
              key: "TasaInteres",
              fieldIso: "interestRate",
              type: "text-custom",
              size: 3,
              isInfo: false,
              required: false,
              icon: "percentage",
              max: 4,
              fClear: () => {
                this.dataEdit.detailsContract.TasaInteres = null;
                this.$forceUpdate();
              },
              fKeyUp: (e) => {
                let parts = e.target.value.split(".");
                let v = parts[0].replace(/\D/g, ""),
                  dec = parts[1];
                let calc_num = Number(dec !== undefined ? v + "." + dec : v);
                console.log("number for calculations: ", calc_num);
                let n = new Intl.NumberFormat("en-EN").format(v);
                n = dec !== undefined ? n + "." + dec : n;
                this.dataEdit.detailsContract.TasaInteres = n;
                this.$forceUpdate();
              },
            },
          ],
        },
        {
          titleIso: "closingCost",
          sizeTitle: 3,
          ref: "conditionalDownPayment",
          // subtitleIso: "validate__msg4",
          isArray: true,
          addMore: () => {
            if (this.dataEdit.conditionalDownPayment.length < 3) {
              this.dataEdit.conditionalDownPayment.push({
                FechaPago: null,
                CargoPago: 0,
                TipoPago: "CDP",
              });
            } else {
              this.$buefy.toast.open({
                message: this.i18n.conditionalDownPayment__msg1[this.iso],
                type: "is-warning",
              });
            }
          },
          list: [
            {
              key: "CargoPago",
              fieldIso: "amount",
              type: "text-custom",
              size: 3,
              isInfo: false,
              required: false,
              icon: "dollar-sign",
              fClear: (idx) => {
                this.dataEdit.conditionalDownPayment[idx].CargoPago = 0;
                this.$forceUpdate();
              },
              fKeyUp: (item, idx) => {
                let parts = item.split(".");
                let v = parts[0].replace(/\D/g, ""),
                  dec = parts[1];
                // let calc_num = Number(dec !== undefined ? v + "." + dec : v);
                // console.log("number for calculations: ", calc_num);
                let n = new Intl.NumberFormat("en-EN").format(v);
                n = dec !== undefined ? n + "." + dec : n;
                this.dataEdit.conditionalDownPayment[idx].CargoPago = n;
                this.$forceUpdate();
              },
            },
            {
              key: "FechaPago",
              fieldIso: "date",
              type: "date",
              size: 3,
              isInfo: false,
              required: false,
            },
          ],
        },
        {
          titleIso: "authorizationPayments",
          sizeTitle: 4,
          ref: "additionalPayments",
          // subtitleIso: "validate__msg4",
          isArray: true,
          addMore: () => {
            if (this.dataEdit.additionalPayments.length < 3) {
              this.dataEdit.additionalPayments.push({
                FechaPago: null,
                CargoPago: 0,
                TipoPago: "ADP",
              });
            } else {
              this.$buefy.toast.open({
                message: this.i18n.additionalPayments__msg1[this.iso],
                type: "is-warning",
              });
            }
          },
          list: [
            {
              key: "CargoPago",
              fieldIso: "amount",
              type: "text-custom",
              size: 3,
              isInfo: false,
              required: false,
              icon: "dollar-sign",
              fClear: (idx) => {
                this.dataEdit.additionalPayments[idx].CargoPago = 0;
                this.$forceUpdate();
              },
              fKeyUp: (item, idx) => {
                let parts = item.split(".");
                let v = parts[0].replace(/\D/g, ""),
                  dec = parts[1];
                // let calc_num = Number(dec !== undefined ? v + "." + dec : v);
                // console.log("number for calculations: ", calc_num);
                let n = new Intl.NumberFormat("en-EN").format(v);
                n = dec !== undefined ? n + "." + dec : n;
                this.dataEdit.additionalPayments[idx].CargoPago = n;
                this.$forceUpdate();
              },
            },
            {
              key: "FechaPago",
              fieldIso: "date",
              type: "date",
              size: 3,
              isInfo: false,
              required: false,
            },
          ],
        },
      ],
    };
  },
  async created() {
    this.iso = localStorage.getItem("iso");
    this.$bus.on("change-iso", () => {
      this.iso = localStorage.getItem("iso");
    });

    //PerPage
    // const perPageLocalStorage = localStorage.getItem("perPageReserves");
    // if (!perPageLocalStorage) this.savePerPage();
    // else this.perPage = perPageLocalStorage;

    //Permissions
    const permissions = localStorage.getItem("permissions");
    if (permissions) this.permissions = JSON.parse(permissions);

    this.role = localStorage.getItem("role");

    //
    await this.getCatalogues();
    await this.getProfile();
  },
  components: {
    CommentMember,
    CommentMemberCap,
    DocContractMemberCap,
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  methods: {
    // savePerPage() {
    //   localStorage.setItem("perPageReserves", this.perPage);
    //   this.listAllMembers();
    // },
    async getProfile() {
      try {
        // this.loading = true;
        const { data } = await axios.get(
          `${API_URL}/catcher/members?idMongo=${this.$route.query?.id}`
        );
        this.clubTemplates = [];
        this.templLoading = null;

        this.data = data;
        this.parseData(this.data);

        // console.log("member", this.member);
        this.loading = false;
      } catch (err) {
        this.loading = false;
        console.log(err);
        this.$buefy.toast.open({
          message: "Error al consultar datos de socio",
          type: "is-danger",
        });
      }
    },
    allowEdit() {
      this.isEdit = true;
    },
    allowCoEdit() {
      this.isCoEdit = true;
    },
    cancelEdit() {
      this.isEdit = false;
      this.parseData(this.data);
    },
    cancelCoEdit() {
      this.isCoEdit = false;
    },
    async editProfile() {
      try {
        this.loading = true;
        await axios.put(`${API_URL}/catcher/members/edit/${this.memberId}`, {
          ...this.clearObjectData(this.member),
        });
        this.cancelEdit();
        this.getProfile();
        this.loading = false;
        this.$buefy.toast.open({
          message: "Updated OK",
          type: "is-success",
        });
      } catch (err) {
        this.loading = false;
        console.log(err);
        let typeError = err?.response?.data?.typeError;
        if (typeError) {
          this.$buefy.toast.open({
            message:
              typeError?.message[this.iso] || "Error al editar datos de socio",
            type: "is-danger",
          });
        } else {
          this.$buefy.toast.open({
            message: "Error al editar datos de socio",
            type: "is-danger",
          });
        }
      }
    },
    async editCoProfile() {
      try {
        this.loading = true;
        await axios.put(`${API_URL}/catcher/members/edit/${this.coMemberId}`, {
          ...this.clearObjectData(this.coOwner),
        });
        this.cancelCoEdit();
        this.getProfile();
        this.loading = false;
        this.$buefy.toast.open({
          message: "Updated OK",
          type: "is-success",
        });
      } catch (err) {
        this.loading = false;
        console.log(err);
        let typeError = err?.response?.data?.typeError;
        if (typeError) {
          this.$buefy.toast.open({
            message:
              typeError?.message[this.iso] ||
              "Error al editar datos de co titular",
            type: "is-danger",
          });
        } else {
          this.$buefy.toast.open({
            message: "Error al editar datos de co titular",
            type: "is-danger",
          });
        }
      }
    },
    parseData(data) {
      if (data?.member) {
        this.memberId = data.member._id;

        for (let i in this.member) {
          this.member[i] = data.member[i];
        }
        if (this.member.FechaNacimiento) {
          this.member.FechaNacimiento = new Date(this.member.FechaNacimiento);
        } else this.member.FechaNacimiento = null;

        if (this.member.FechaRegistro) {
          this.member.FechaRegistro = moment(this.member.FechaRegistro).format(
            "DD-MM-YYYY"
          );
        } else this.member.FechaRegistro = null;

        if (data.member?.coOwner) {
          this.coMemberId = data.member?.coOwner._id;
          for (let i in this.coOwner) {
            this.coOwner[i] = data.member.coOwner[i];
          }
          if (this.coOwner.FechaNacimiento) {
            this.coOwner.FechaNacimiento = new Date(
              this.coOwner.FechaNacimiento
            );
          } else this.coOwner.FechaNacimiento = null;

          if (this.coOwner.FechaRegistro) {
            this.coOwner.FechaRegistro = moment(
              this.coOwner.FechaRegistro
            ).format("DD-MM-YYYY");
          } else this.coOwner.FechaRegistro = null;
        }

        if (data?.member?.ContratoId) {
          this.contract = data.member.ContratoId;
          this.statusContractOld = this.contract.EstadoContrato;
          this.club.Nombre = data.member.ContratoId?.ClubId?.Nombre || "";
          this.club.Id = data.member.ContratoId?.ClubId?.Id || null;
          this.club.ModuloPagos =
            data.member.ContratoId?.ClubId?.ModuloPagos || false;
          this.club.ModuloUpDowngrade =
            data.member.ContratoId?.ClubId?.ModuloUpDowngrade || false;

          this.club.Plantillas = [];
          if (data.member.ContratoId?.ClubId?.Plantillas) {
            const Plantillas = data.member.ContratoId?.ClubId?.Plantillas;
            const existFraf =
              data?.member?.beneficiaries &&
              data?.member?.beneficiaries.length > 0;
            for (let i in Plantillas) {
              const doc = data.member?.files.find((f) => f.Referencia === i);
              const docPayload = {
                Referencia: i,
                Nombre: doc?.Nombre || null,
                FechaCreacion: doc?.FechaCreacion || null,
                Id: doc?.Id || null,
                CreadoPor: doc?.CreadoPor || null,
              };
              if (i === "fraf" && existFraf) {
                this.club.Plantillas.push(docPayload);
              } else if (i !== "fraf") {
                this.club.Plantillas.push(docPayload);
              }
            }
          }
          // const clubTemplates = data.member.ContratoId?.ClubId?.Plantillas;
          // if (clubTemplates) {
          //   for (let i in clubTemplates) this.clubTemplates.push(i);
          // }
        }

        if (data?.member?.renovations) {
          this.renovations = data.member.renovations;
        }

        if (data?.member?.statusAccount) {
          this.statusAccounts = data.member.statusAccount;
        }

        if (data?.member?.detailsContract) {
          let tmp = data.member.detailsContract;

          this.TravelVoucher = tmp.TravelVoucher || [];

          delete tmp._id;
          delete tmp.Eliminado;
          delete tmp.FechaRegistro;
          delete tmp.__v;
          delete tmp.TravelVoucher;
          delete tmp.ContratoId;
          this.detailsContract = tmp;
        }

        if (data?.member?.beneficiaries) {
          this.beneficiaries = data.member.beneficiaries;
        }

        if (data?.member?.aditionalPayments) {
          for (let item of data?.member?.aditionalPayments) {
            let parts = item.CargoPago.toString().split(".");
            let v = parts[0].replace(/\D/g, ""),
              dec = parts[1];
            let n = new Intl.NumberFormat("en-EN").format(v);
            n = dec !== undefined ? n + "." + dec : n;
            if (item.TipoPago === "CDP")
              this.dataEdit.conditionalDownPayment.push({
                ...item,
                CargoPago: n,
                FechaPago: new Date(item.FechaPago),
              });
            else
              this.dataEdit.additionalPayments.push({
                ...item,
                CargoPago: n,
                FechaPago: new Date(item.FechaPago),
              });
          }
        }

        console.log(this.member);
      }
    },
    async getCatalogues() {
      try {
        this.loading = true;

        const promises = [];

        for (let cat of listCatalogues) {
          promises.push(
            axios
              .get(`${API_URL}/catalogue/${cat}`)
              .then(({ data }) => {
                this.catalogues[cat] = data;
              })
              .catch((err) => {
                console.log(err);
                this.$buefy.toast.open({
                  message: "Error al consultar datos de catalogos",
                  type: "is-danger",
                });
              })
          );
          // const { data } = await axios.get(`${API_URL}/catalogue/${cat}`);
          // this.catalogues[cat] = data;
        }

        await Promise.all(promises);

        // console.log("catalogues", this.catalogues);
        // this.loading = false;
      } catch (err) {
        this.loading = false;
        console.log(err);
        this.$buefy.toast.open({
          message: "Error al consultar datos de catalogos",
          type: "is-danger",
        });
      }
    },
    parseDate(value) {
      moment.locale(this.iso);
      return value ? moment(value).utc().format("LL") : "";
    },
    emitGoLastComment() {
      this.$refs.commentmember.goLastToComment();
    },
    actionTab(tab) {
      if (tab === 3) this.emitGoLastComment();
    },
    renew() {
      this.$buefy.dialog.prompt({
        message: this.i18n.renew__message1[this.iso],
        confirmText: this.i18n.next[this.iso],
        inputAttrs: {
          type: "number",
          value: 1,
          min: 0,
          max: 99,
        },
        trapFocus: true,
        onConfirm: (years) => {
          this.$buefy.dialog.prompt({
            message: this.i18n.renew__message2[this.iso],
            confirmText: this.i18n.send[this.iso],
            inputAttrs: {
              maxlength: 10,
              required: false,
            },
            trapFocus: true,
            onConfirm: (cupon) => this.sendRenew(years, cupon),
          });
        },
      });
    },
    async sendRenew(qty, cupon) {
      try {
        // this.loading = true;
        await axios.post(`${API_URL}/renewals/create`, {
          contractId: this.contract.Id,
          qty,
          agentEmail: localStorage.getItem("userEmail" || null),
        });
        this.$buefy.toast.open({
          message: "Renewal OK",
          type: "is-success",
        });
      } catch (err) {
        console.log(err);
        this.$buefy.toast.open({
          message: "Error al renovar socio",
          type: "is-danger",
        });
      }
    },
    async editStatusMembership() {
      try {
        this.loadingButtonEditStatusMembership = true;
        await axios.put(
          `${API_URL}/catcher/edit-status-membership/contract/${
            this.contract.Id
          }?updatedBy=${localStorage.getItem("user")}`
        );
        this.loadingButtonEditStatusMembership = false;
        this.$buefy.toast.open({
          message: "OK",
          type: "is-success",
        });
        await this.getProfile();
        await this.$bus.emit("refresh-comments-cap");
      } catch (err) {
        console.log(err);
        this.loadingButtonEditStatusMembership = false;
        this.$buefy.toast.open({
          message: "Error al editar contrato",
          type: "is-danger",
        });
      }
    },
    editStatusContractConfirm() {
      let message = this.i18n.changeStatusContract__mg4[this.iso];
      if (this.contract.EstadoContrato === 1) {
        message = message.replace("{{status}}", this.i18n.pending[this.iso]);
      }
      if (this.contract.EstadoContrato === 2) {
        message = message.replace("{{status}}", this.i18n.procesable[this.iso]);
      }
      if (this.contract.EstadoContrato === 3) {
        message = message.replace("{{status}}", this.i18n.cancelled[this.iso]);
      }
      this.$buefy.dialog.confirm({
        message,
        type: "is-info",
        onConfirm: async () => await this.editStatusContract(),
        onCancel: () => (this.contract.EstadoContrato = this.statusContractOld),
      });
    },
    async editStatusContract() {
      try {
        // this.loadingButtonEditStatusContract = true;
        await axios.put(
          `${API_URL}/catcher/edit-status/contract/${this.contract.Id}?status=${
            this.contract.EstadoContrato
          }&updatedBy=${localStorage.getItem("user")}`
        );
        // this.loadingButtonEditStatusContract = false;
        this.$buefy.toast.open({
          message: this.i18n.changeStatusContract__mg5[this.iso],
          type: "is-success",
        });
        await this.getProfile();
        await this.$bus.emit("refresh-comments-cap");
      } catch (err) {
        console.log(err);
        // this.loadingButtonEditStatusContract = false;
        this.$buefy.toast.open({
          message: "Error al editar contrato",
          type: "is-danger",
        });
      }
    },
    async sendEmailStatusMembership() {
      try {
        this.loadingButtonSendEmailMembershipStatus = true;
        await axios.put(
          `${API_URL}/catcher/edit-status-membership/contract/${
            this.contract.Id
          }?forceTrue=y&updatedBy=${localStorage.getItem("user")}`
        );
        this.loadingButtonSendEmailMembershipStatus = false;
        this.$buefy.toast.open({
          message: "OK",
          type: "is-success",
        });
        await this.getProfile();
        await this.$bus.emit("refresh-comments-cap");
      } catch (err) {
        console.log(err);
        this.loadingButtonSendEmailMembershipStatus = false;
        this.$buefy.toast.open({
          message: "Error al enviar email",
          type: "is-danger",
        });
      }
    },
    async downloadPdfOwners(type) {
      try {
        //Loadings
        this.templLoading = type;

        const { data } = await axios.get(
          `${API_URL}/catcher/pdf-download/owners?type=${type}&contractId=${this.contract.Id}`
        );

        const url = window.URL.createObjectURL(
          new Blob([new Uint8Array(data.pdf.data).buffer])
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `Document_${Date.now()}.pdf`);
        document.body.appendChild(link);
        link.click();

        //Loadings
        this.templLoading = null;

        // this.$buefy.toast.open({
        //   message: this.i18n.documentGeneratedSuccessfully[this.iso],
        //   type: "is-success",
        // });
        this.$buefy.dialog.alert({
          // title: this.i18n.successfulOperation[this.iso],
          message: this.i18n.documentGeneratedSuccessfully[this.iso],
          type: "is-success",
          hasIcon: true,
          icon: "check-circle",
          iconPack: "fa",
          ariaRole: "alertdialog",
          ariaModal: true,
        });
        // await this.getProfile();
      } catch (err) {
        console.log(err);
        this.templLoading = null;
        // this.$buefy.toast.open({
        //   message: this.i18n.documentGeneratedError[this.iso],
        //   type: "is-danger",
        // });
        this.$buefy.dialog.alert({
          // title: this.i18n.validationError[this.iso],
          message: this.i18n.documentGeneratedError[this.iso],
          type: "is-danger",
          hasIcon: true,
          icon: "times-circle",
          iconPack: "fa",
          ariaRole: "alertdialog",
          ariaModal: true,
        });
      }
      // window.open(
      //   `${API_URL}/catcher/pdf-download/owners?type=${type}&contractId=${this.contract.Id}`,
      //   "_blank"
      // );
      // window.open(
      //   `https://6ff6-190-6-42-169.ngrok-free.app/api/catcher/pdf-download/owners?type=${type}&contractId=${this.contract.Id}`,
      //   "_blank"
      // );
      // https://6ff6-190-6-42-169.ngrok-free.app
    },
    async uploadFile() {
      try {
        this.loadingButtonUploadPdfContract = true;

        const formData = new FormData();
        formData.append("pdf", this.file);
        const headers = { "Content-Type": "multipart/form-data" };

        const { data } = await axios.post(
          `${API_URL}/upload/contract/${this.contract.Id}`,
          formData,
          {
            headers,
          }
        );

        this.loadingButtonUploadPdfContract = false;

        this.file = null;

        this.$buefy.dialog.alert({
          // title: this.i18n.successfulOperation[this.iso],
          message: this.i18n.documentUploadSuccessfully[this.iso],
          type: "is-success",
          hasIcon: true,
          icon: "check-circle",
          iconPack: "fa",
          ariaRole: "alertdialog",
          ariaModal: true,
        });
        await this.getProfile();
      } catch (err) {
        console.log(err);
        this.loadingButtonUploadPdfContract = false;
        this.$buefy.dialog.alert({
          // title: this.i18n.validationError[this.iso],
          message: this.i18n.documentUploadError[this.iso],
          type: "is-danger",
          hasIcon: true,
          icon: "times-circle",
          iconPack: "fa",
          ariaRole: "alertdialog",
          ariaModal: true,
        });
      }
    },
    showDoc(filename) {
      // console.log("showww");
      window.open(
        `${API_URL}/file/show/uploads?filename=${filename}`,
        "_blank"
      );
    },
    clearObjectData(obj) {
      let tmp = {};
      for (let i in obj) {
        if (obj[i] !== null) tmp[i] = obj[i];
      }
      return tmp;
    },
    checkI18n(item) {
      return this.i18n && this.i18n[item] ? this.i18n[item][this.iso] : item;
    },
    async editFF(IdFF, idx) {
      try {
        if (this.beneficiaries[idx].edit) {
          this.beneficiaries[idx].loading = true;
          this.$forceUpdate();
          await axios.put(`${API_URL}/catcher/members/edit/${IdFF}`, {
            Nombre: this.beneficiaries[idx].Nombre,
            ApellidoPaterno: this.beneficiaries[idx].ApellidoPaterno,
            Parentesco: this.beneficiaries[idx].Parentesco,
          });
          await this.getProfile();
          this.beneficiaries[idx].edit = false;
          this.beneficiaries[idx].loading = false;
          this.$buefy.toast.open({
            message: "OK",
            type: "is-success",
          });
        } else {
          this.beneficiaries[idx].edit = true;
          this.$forceUpdate();
        }
      } catch (err) {
        console.log(err);
        this.beneficiaries[idx].loading = false;
        this.$buefy.toast.open({
          message: "Error al actualizar beneficiario",
          type: "is-danger",
        });
      }
    },
    async createFF(idx) {
      try {
        this.beneficiaries[idx].loading = true;

        if (
          !this.beneficiaries[idx].Nombre ||
          !this.beneficiaries[idx].ApellidoPaterno ||
          !this.beneficiaries[idx].Parentesco
        ) {
          this.$buefy.dialog.alert({
            title: this.i18n.validationError[this.iso],
            message: this.i18n.allFieldsAreRequired[this.iso],
            type: "is-danger",
            hasIcon: true,
            icon: "times-circle",
            iconPack: "fa",
            ariaRole: "alertdialog",
            ariaModal: true,
          });
          this.beneficiaries[idx].loading = false;
          return;
        }

        const payload = {
          ContratoId: this.contract.Id,
          Nombre: this.beneficiaries[idx].Nombre,
          ApellidoPaterno: this.beneficiaries[idx].ApellidoPaterno,
          Parentesco: this.beneficiaries[idx].Parentesco,
          Seal: this.SEAL,
          Idioma: this.contract.Idioma,
          TipoSocio: "F",
          Password: this.contract.LoginID,
        };

        await axios.post(`${API_URL}/catcher/members/create`, payload);

        this.beneficiaries[idx].edit = false;
        await this.getProfile();
        this.beneficiaries[idx].loading = false;
        this.$forceUpdate();
      } catch (err) {
        this.beneficiaries[idx].loading = false;
        console.log(err);
        this.$buefy.toast.open({
          message: "Error al crear beneficiario",
          type: "is-danger",
        });
      }
    },
    cancelEditFF(idx) {
      this.beneficiaries[idx].edit = false;
      this.$forceUpdate();
    },
    upDownContract(type) {
      this.$buefy.dialog.confirm({
        title:
          type === "up"
            ? this.i18n.upgrade[this.iso]
            : this.i18n.downgrade[this.iso],
        message: this.i18n.questionPreAction[this.iso],
        cancelText: this.i18n.cancel[this.iso],
        confirmText: this.i18n.accept[this.iso],
        hasIcon: true,
        type: "is-warning",
        onConfirm: () => {
          let tmp = {};
          for (let i in this.detailsContract) {
            if (i !== "FechaPrimeraMensualidad") {
              let parts = this.detailsContract[i].toString().split(".");
              let v = parts[0].replace(/\D/g, ""),
                dec = parts[1];
              let n = new Intl.NumberFormat("en-EN").format(v);
              n = dec !== undefined ? n + "." + dec : n;
              tmp[i] = n;
            } else tmp[i] = this.detailsContract[i];
          }
          this.dataEdit.detailsContract = {
            ...tmp,
            FechaPrimeraMensualidad:
              (this.detailsContract?.FechaPrimeraMensualidad &&
                new Date(this.detailsContract?.FechaPrimeraMensualidad)) ||
              null,
          };
          this.changeDetailsContract = true;
          this.typeUpDown = type;
          this.$forceUpdate();
        },
      });
    },
    async updateDetailsContract() {
      try {
        let tmp = {};
        for (let i in this.dataEdit.detailsContract) {
          if (i !== "FechaPrimeraMensualidad") {
            let parts = this.dataEdit.detailsContract[i].toString().split(".");
            let v = parts[0].replace(/\D/g, ""),
              dec = parts[1];
            let calc_num = Number(dec !== undefined ? v + "." + dec : v);
            // let n = new Intl.NumberFormat("en-EN").format(v);
            // n = dec !== undefined ? n + "." + dec : n;
            // console.log("i", calc_num);
            tmp[i] = calc_num;
          } else tmp[i] = this.dataEdit.detailsContract[i];
        }
        await axios.put(
          `${API_URL}/catcher/details-contract/edit/${this.contract.Id}?type=${this.typeUpDown}&years=${this.contract.Years}`,
          {
            ...tmp,
          }
        );
        this.typeUpDown = null;
        this.changeDetailsContract = false;
        this.$buefy.toast.open({
          message: i18n.upgradeDowngradeOk[this.iso],
          type: "is-success",
        });
        await this.getProfile();
        this.$forceUpdate();
      } catch (err) {
        console.log(err);
        this.$buefy.toast.open({
          message: "Error al actualizar detalles del contrato",
          type: "is-danger",
        });
      }
    },
    async editPagosAdicionalesContract() {
      try {
        await axios.put(
          `${API_URL}/catcher/details-contract/edit/${this.contract.Id}`,
          {
            ...this.dataEdit.detailsContract,
          }
        );
        // await this.getProfile();
      } catch (err) {
        console.log(err);
        this.$buefy.toast.open({
          message: "Error al actualizar detalles del contrato",
          type: "is-danger",
        });
      }
    },
    beforeEditLenguage() {
      this.$buefy.dialog.confirm({
        title: this.i18n.changeLenguage[this.iso],
        message: this.i18n.changeLanguageContract[this.iso],
        cancelText: "No",
        confirmText: this.i18n.yes[this.iso],
        type: "is-info",
        onConfirm: async () => await this.editLenguage(),
      });
    },
    async editLenguage() {
      try {
        // this.loading = true;
        await axios.put(
          `${API_URL}/catcher/contract/edit/${this.contract.Id}`,
          {
            Idioma: this.contract.Idioma,
          }
        );

        // this.showModal = false;
        this.$buefy.toast.open({
          message: this.i18n.changeLanguageContract__msg1[this.iso],
          type: "is-success",
        });
        // await this.getProfile();
      } catch (err) {
        console.log(err);
        this.showModal = false;
        this.$buefy.toast.open({
          message: "Error al editar contrato",
          type: "is-danger",
        });
      }
    },
    calcularPagos(value, key) {
      if (
        this.dataEdit.detailsContract.PrecioContrato &&
        key === "PagoInicial"
      ) {
        let PrecioContrato = parseFloat(
          this.parseIntNumberDecimals(
            this.dataEdit.detailsContract.PrecioContrato
          )
        );
        let PagoInicial = parseFloat(
          this.parseIntNumberDecimals(this.dataEdit.detailsContract.PagoInicial)
        );

        this.dataEdit.detailsContract.PorcentajePagoInicial =
          this.formatNumberDecimals(
            ((PagoInicial / PrecioContrato) * 100).toFixed(2)
          );
        this.$forceUpdate();
      }

      if (
        this.dataEdit.detailsContract.PrecioContrato &&
        key === "PorcentajePagoInicial"
      ) {
        let PrecioContrato = parseFloat(
          this.parseIntNumberDecimals(
            this.dataEdit.detailsContract.PrecioContrato
          )
        );
        let PorcentajePagoInicial = parseFloat(
          this.parseIntNumberDecimals(
            this.dataEdit.detailsContract.PorcentajePagoInicial
          )
        );

        this.dataEdit.detailsContract.PagoInicial = this.formatNumberDecimals(
          (PorcentajePagoInicial / 100) * PrecioContrato
        );
        this.$forceUpdate();
      }

      if (this.dataEdit.detailsContract.PrecioContrato) {
        let PrecioContrato = parseFloat(
          this.parseIntNumberDecimals(
            this.dataEdit.detailsContract.PrecioContrato
          )
        );
        let CostoAdministracion = parseFloat(
          this.parseIntNumberDecimals(
            this.dataEdit.detailsContract.CostoAdministracion || 0
          )
        );
        let PagoInicial = parseFloat(
          this.parseIntNumberDecimals(
            this.dataEdit.detailsContract.PagoInicial || 0
          )
        );
        // let cdps = this.data.conditionalDownPayment.reduce((acum, value) => {
        //   let CargoPago = parseFloat(
        //     this.parseIntNumberDecimals(value.CargoPago || 0)
        //   );
        //   acum += CargoPago;
        //   return acum;
        // }, 0);
        let cdps = 0;
        let adps = this.dataEdit.additionalPayments.reduce((acum, value) => {
          let CargoPago = parseFloat(
            this.parseIntNumberDecimals(value.CargoPago || 0)
          );
          acum += CargoPago;
          return acum;
        }, 0);
        this.SaldoNumber = PrecioContrato - PagoInicial - cdps - adps;
        if (this.SaldoNumber <= 0) {
          this.dataEdit.detailsContract.Saldo = 0;
          this.dataEdit.detailsContract.TasaInteres = 0;
          this.dataEdit.detailsContract.Mensualidades = 0;
          this.dataEdit.detailsContract.FactorIA = 0;
          this.dataEdit.detailsContract.PagoMensual = 0;
          // this.data.detailsContract.FechaPrimeraMensualidad = moment()
        }

        if (this.SaldoNumber < 0) {
          this.dataEdit.detailsContract.Saldo = this.SaldoNumber;
        } else {
          this.dataEdit.detailsContract.Saldo = this.formatNumberDecimals(
            PrecioContrato - PagoInicial - cdps - adps
          );
        }

        this.$forceUpdate();
      }

      if (this.dataEdit.detailsContract.TasaInteres) {
        let TasaInteres = parseFloat(
          this.parseIntNumberDecimals(
            this.dataEdit.detailsContract.TasaInteres || 0
          )
        );

        this.dataEdit.detailsContract.FactorIA = this.formatNumberDecimals(
          ((Math.pow(1 + TasaInteres / 100, 1 / 12) - 1) * 100).toFixed(4)
        );
      }

      if (
        this.dataEdit.detailsContract.Mensualidades &&
        this.dataEdit.detailsContract.FactorIA
      ) {
        let Saldo = parseFloat(
          this.parseIntNumberDecimals(this.dataEdit.detailsContract.Saldo || 0)
        );

        let Mensualidades = parseFloat(
          this.parseIntNumberDecimals(
            this.dataEdit.detailsContract.Mensualidades || 0
          )
        );

        let FactorIA = parseFloat(
          this.parseIntNumberDecimals(
            this.dataEdit.detailsContract.FactorIA || 0
          )
        );
        FactorIA = FactorIA / 100;

        // console.log("Saldo", Saldo);
        // console.log("Mensualidades", Mensualidades);
        // console.log("FactorIA", FactorIA);

        let a = FactorIA * Math.pow(1 + FactorIA, Mensualidades);
        let b = Math.pow(1 + FactorIA, Mensualidades) - 1;
        // a = !a ? 1 : a
        // b = !b ? 1 : b
        let ab = a / b;

        // console.log("a", a);
        // console.log("b", b);

        if (ab > 0) {
          this.dataEdit.detailsContract.PagoMensual = this.formatNumberDecimals(
            (Saldo * (a / b)).toFixed(2)
          );
        } else {
          this.dataEdit.detailsContract.PagoMensual = this.formatNumberDecimals(
            (Saldo / Mensualidades).toFixed(2)
          );
        }
      }
    },
    formatNumberDecimals(value) {
      let parts = value.toString().split(".");
      let v = parts[0].replace(/\D/g, ""),
        dec = parts[1];
      let n = new Intl.NumberFormat("en-EN").format(v);
      n = dec !== undefined ? n + "." + dec : n;
      return n;
    },
    parseIntNumberDecimals(value) {
      let parts = value.toString().split(".");
      let v = parts[0].replace(/\D/g, ""),
        dec = parts[1];
      let calc_num = Number(dec !== undefined ? v + "." + dec : v);
      return calc_num;
    },
  },
};
</script>

<style scoped>
.articles {
  margin: 5rem 0;
  margin-top: -200px;
}
.articles .content p {
  line-height: 1.9;
  margin: 15px 0;
}
.author-image {
  position: absolute;
  top: -30px;
  left: 50%;
  width: 60px;
  height: 60px;
  margin-left: -30px;
  border: 3px solid #ccc;
  border-radius: 50%;
}
.media-center {
  display: block;
  margin-bottom: 1rem;
}
.media-content {
  margin-top: 3rem;
}
.article,
.promo-block {
  margin-top: 6rem;
}
div.column.is-8:first-child {
  padding-top: 0;
  margin-top: 0;
}
.article-title {
  font-size: 2rem;
  font-weight: lighter;
  line-height: 2;
}
.article-subtitle {
  color: #909aa0;
  margin-bottom: 3rem;
}
.article-body {
  line-height: 1.4;
  margin: 0 6rem;
}
.promo-block .container {
  margin: 1rem 5rem;
}
</style>

<style>
tr.is-selected-row {
  background: #7eb0e9;
  color: #fff;
}
</style>
